import React from 'react';
import PropTypes from 'prop-types';
import systemColors from 'utils/constants/styles/system_colors';

export default function ChevronRightIcon({ $height = 25, $width = 25 }) {
  return (
    <svg
      width={$width}
      height={$height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={
          'M8.94775 17.2708L13.7186 12.5L8.94775 7.71875L10.4165 6.25L16.6665 12.5L10.4165 18.75L8.94775'
          + ' 17.2708Z'
        }
        fill={systemColors.inactiveColor}
      />
    </svg>
  );
}

ChevronRightIcon.propTypes = {
  $height: PropTypes.number,
  $width: PropTypes.number,
};
