import React from 'react';
import PropTypes from 'prop-types';
import { CloseIcon } from 'components/icons';
import styled from 'styled-components';
import systemColors from 'utils/constants/styles/system_colors';

const ButtonWrapper = styled.div`
  background: ${systemColors.bgColor};
  border-radius: 100px;
  width: ${({ $width }) => `${$width}px;`}
  height: ${({ $height }) => `${$height}px;`}
  position: relative;
  box-sizing: border-box;
  border: ${({ $borderSize }) => `${$borderSize}px`} solid ${systemColors.bgColor};
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ $backgroundColor }) => $backgroundColor && `
    border-color: ${$backgroundColor};
    background: ${$backgroundColor};
  `}

  &:hover {
    cursor: pointer;
    border: ${({ $borderSize }) => `${$borderSize}px`} solid ${systemColors.hoverColor};
  }
`;

const sizeMap = {
  medium: {
    height: 40, width: 40, borderSize: 3, iconHeight: 25, iconWidth: 25,
  },
  small: {
    height: 25, width: 25, borderSize: 1.5, iconHeight: 17.5, iconWidth: 17.5,
  },
};

export default function CloseButton({
  $backgroundColor, $size = 'medium', onClick,
}) {
  const sizeOptions = sizeMap[$size];

  return (
    <ButtonWrapper
      $backgroundColor={$backgroundColor}
      $height={sizeOptions.height}
      $width={sizeOptions.width}
      $borderSize={sizeOptions.borderSize}
      onClick={onClick}
    >
      <CloseIcon $height={sizeOptions.iconHeight} $width={sizeOptions.iconWidth} />
    </ButtonWrapper>
  );
}

CloseButton.propTypes = {
  $backgroundColor: PropTypes.string,
  $size: PropTypes.string,
  onClick: PropTypes.func,
};
