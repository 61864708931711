import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { CloseIcon } from 'components/icons';
import * as S from './Input.styles';

export default function Input({ allowClear, ...props }) {
  const { onChange, name, value } = props;

  const handleReset = useCallback(() => {
    onChange({ target: { value: '', name } });
  }, [onChange, name]);

  return (
    <>
      <S.StyledInput
        $allowClear={allowClear}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      {allowClear && value && <S.ResetIconWrapper onClick={handleReset}><CloseIcon /></S.ResetIconWrapper>}
    </>
  );
}

Input.propTypes = {
  allowClear: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
};
