import {
  useState, useCallback, useEffect, useRef,
} from 'react';

export default function useOnHover() {
  const elementRef = useRef({});
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  useEffect(() => {
    const refCurrent = elementRef.current;

    if (refCurrent) {
      refCurrent.addEventListener('mouseenter', handleMouseEnter);
      refCurrent.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (refCurrent) {
        refCurrent.removeEventListener('mouseenter', handleMouseEnter);
        refCurrent.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, [elementRef, handleMouseEnter, handleMouseLeave]);

  return [elementRef, isHovered];
}
