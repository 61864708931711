import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import SignaturePadCanvas from 'signature_pad';
import * as S from './SignaturePad.styles';

export default function SignaturePad({
  canEdit, imageContent, onChange,
}) {
  const canvas = useRef({});
  const signaturePad = useRef({});
  const canvasWidth = 560;
  const canvasHeight = 300;

  const resizeCanvas = useCallback(() => {
    canvas.current.width = canvasWidth;
    canvas.current.height = canvasHeight;
    canvas.current.getContext('2d').scale(1, 1);
    signaturePad.current.clear();
  }, []);

  const handleStroke = useCallback(() => {
    let value = null;
    if (!signaturePad.current.isEmpty()) { value = signaturePad.current.toDataURL(); }

    onChange(value);
  }, [onChange]);

  useEffect(() => {
    signaturePad.current = new SignaturePadCanvas(canvas.current);
    resizeCanvas();
    signaturePad.current.fromDataURL(imageContent);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    signaturePad.current.addEventListener('endStroke', handleStroke);

    return () => {
      signaturePad.current.removeEventListener('endStroke', handleStroke);
    };
  }, [handleStroke]);

  useEffect(() => {
    // Temporary fix of https://github.com/szimek/signature_pad/issues/373
    // It is unable to actualize imageContent by effect hook because of
    // incorrect rendering with zoom other than 100%
    if (!imageContent) {
      signaturePad.current.clear();
    }
  }, [imageContent]);

  useEffect(() => {
    if (canEdit) {
      signaturePad.current.on();
    } else {
      signaturePad.current.off();
    }
  }, [canEdit]);

  return (
    <S.SignatureInputCanvas ref={canvas} />
  );
}

SignaturePad.propTypes = {
  canEdit: PropTypes.bool,
  imageContent: PropTypes.string,
  onChange: PropTypes.func,
};
