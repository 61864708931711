import styled from 'styled-components';
import systemColors from 'utils/constants/styles/system_colors';

const Default = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Error = styled(Default)`
  color: ${systemColors.errorColor};
`;

const Alert = styled(Default)`
  color: ${systemColors.alertColor};
`;

export default { Error, Alert, Default };
