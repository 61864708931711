import React from 'react';
import PropTypes from 'prop-types';
import systemColors from 'utils/constants/styles/system_colors';

export default function PlusIcon({ $height = 15, $width = 15 }) {
  return (
    <svg width={$width} height={$height} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5 8.75H8.75V12.5H6.25V8.75H2.5V6.25H6.25V2.5H8.75V6.25H12.5V8.75Z"
        fill={systemColors.defaultColor}
      />
    </svg>
  );
}

PlusIcon.propTypes = {
  $height: PropTypes.number,
  $width: PropTypes.number,
};
