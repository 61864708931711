import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import { CalendarIcon, CloseIcon } from 'components/icons';
import TimeInput from './TimeInput';
import * as S from './DatePicker.styles';

registerLocale('ru', ru);

export default function DatePicker({
  allowClear, withTime, onChange, selected, ...props
}) {
  const handleReset = useCallback(() => {
    onChange('');
  }, [onChange]);

  return (
    <S.PickerWrapper>
      <S.StyledDatePicker
        dropdownMode="scroll"
        dateFormat={withTime ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy'}
        yearDropdownItemNumber={50}
        showTimeInput={withTime}
        timeInputLabel=""
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        selected={selected}
        locale="ru"
        onChange={onChange}
        customTimeInput={<TimeInput />}
      />
      { allowClear && selected && (
        <S.ResetIconWrapper onClick={handleReset}>
          <CloseIcon />
        </S.ResetIconWrapper>
      )}
      <S.CalendarIconWrapper><CalendarIcon /></S.CalendarIconWrapper>
    </S.PickerWrapper>
  );
}

DatePicker.propTypes = {
  selected: PropTypes.object,
  withTime: PropTypes.bool,
  allowClear: PropTypes.bool,
  onChange: PropTypes.func,
};
