import styled from 'styled-components';
import { Input as AntdInput } from 'antd';
import systemColors from 'utils/constants/styles/system_colors';

const TextArea = styled(AntdInput.TextArea)`
  width: 100%;
  padding: 20px;
  border: 2px solid ${systemColors.darkGrayColor};
  box-sizing: border-box;
  border-radius: 10px;
  background: white;
  font-size: 20px;
  line-height: 27px;
`;

export default TextArea;
