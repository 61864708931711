import styled from 'styled-components';
import systemColors from 'utils/constants/styles/system_colors';

const sizeMap = {
  large: { height: 67 },
  medium: { height: 52 },
};

export const StyledFictiveInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding: 20px;

  height: ${({ $size }) => sizeMap[$size]?.height || sizeMap.medium.height}px;
  background: #FFFFFF;
  border: 2px solid ${systemColors.darkGrayColor};
  border-radius: 10px;

  font-family: 'OpenSans';
  font-weight: 400;
  font-size: 16px;
  line-height: 135%;
  cursor: pointer;

  ${({ $isActive }) => $isActive && `
    border: 2px solid ${systemColors.defaultColor};
  `}
`;

export const InputContent = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const PostfixContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 5px;
`;

export const ResetIconWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 5px;
`;

export const RightContentWrapper = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const PrefixContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 5px;
`;

export const ContentContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
