import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SvgWrapper = styled.svg`
  ${({ onClick }) => onClick && `
    cursor: pointer;
  `}
`;

export default function ChevronDoubleUpIcon({ title, onClick }) {
  return (
    <SvgWrapper
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <title>{title}</title>
      <path
        d={
          'M27.35 30.35L25 28L35 18L45 28L42.65 30.35L35 22.7167L27.35 30.35ZM27.35 20.35L25 18L35 8L45'
          + ' 18L42.65 20.35L35 12.7167L27.35 20.35Z'
        }
        fill="#C4C5C6"
      />
    </SvgWrapper>
  );
}

ChevronDoubleUpIcon.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
};
