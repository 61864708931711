import React from 'react';
import systemColors from 'utils/constants/styles/system_colors';

export default function MaintenanceEntityIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d={
          'M11.25 11.875C11.25 11.7092 11.3158 11.5503 11.4331 11.4331C11.5503 11.3158 11.7092 11.25 11.875 '
          + '11.25H14.375C14.5408 11.25 14.6997 11.3158 14.8169 11.4331C14.9342 11.5503 15 11.7092 15 '
          + '11.875V14.375C15 14.5408 14.9342 14.6997 14.8169 14.8169C14.6997 14.9342 14.5408 15 14.375 '
          + '15H11.875C11.7092 15 11.5503 14.9342 11.4331 14.8169C11.3158 14.6997 11.25 14.5408 11.25 '
          + '14.375V11.875ZM12.5 13.75H13.75V12.5H12.5V13.75ZM5.235 1.3875L2.11 3.8875C1.9025 4.0525 1.875 '
          + '4.3375 1.875 4.5875V6.875C1.875 7.57875 2.1075 8.2275 2.5 8.75V18.125C2.5 18.2908 2.56585 '
          + '18.4497 2.68306 18.5669C2.80027 18.6842 2.95924 18.75 3.125 18.75H16.875C17.0408 18.75 17.1997 '
          + '18.6842 17.3169 18.5669C17.4342 18.4497 17.5 18.2908 17.5 18.125V8.75C17.8925 8.2275 18.125 '
          + '7.57875 18.125 6.875V4.5825C18.125 4.3325 18.0975 4.0525 17.89 3.8875L14.765 1.3875C14.6544 '
          + '1.29874 14.5168 1.25025 14.375 1.25H5.625C5.48316 1.25025 5.34563 1.29874 5.235 1.3875ZM6.875 '
          + '5V6.875C6.875 7.37228 6.67746 7.84919 6.32583 8.20083C5.97419 8.55246 5.49728 8.75 5 '
          + '8.75C4.50272 8.75 4.02581 8.55246 3.67417 8.20083C3.32254 7.84919 3.125 7.37228 3.125 '
          + '6.875V5H6.875ZM11.875 5V6.875C11.875 7.37228 11.6775 7.84919 11.3258 8.20083C10.9742 8.55246 '
          + '10.4973 8.75 10 8.75C9.50272 8.75 9.02581 8.55246 8.67417 8.20083C8.32254 7.84919 8.125 7.37228 '
          + '8.125 6.875V5H11.875ZM16.875 5V6.875C16.875 7.37228 16.6775 7.84919 16.3258 8.20083C15.9742 '
          + '8.55246 15.4973 8.75 15 8.75C14.5027 8.75 14.0258 8.55246 13.6742 8.20083C13.3225 7.84919 '
          + '13.125 7.37228 13.125 6.875V5H16.875ZM7.53625 2.5L7.0675 3.75H4.28125L5.84375 '
          + '2.5H7.5375H7.53625ZM8.4025 3.75L8.87125 2.5H11.1125L11.5287 3.75H8.4025ZM12.43 '
          + '2.5H14.155L15.7175 3.75H12.8463L12.43 2.5ZM16.25 9.74V17.5H10V11.875C10 11.7092 9.93415 11.5503 '
          + '9.81694 11.4331C9.69973 11.3158 9.54076 11.25 9.375 11.25H5.625C5.45924 11.25 5.30027 11.3158 '
          + '5.18306 11.4331C5.06585 11.5503 5 11.7092 5 11.875V17.5H3.75V9.74C4.39507 10.0224 5.11668 '
          + '10.0782 5.79752 9.89849C6.47836 9.71875 7.07837 9.31398 7.5 8.75C8.07 9.50875 8.9775 10 10 '
          + '10C11.0225 10 11.93 9.50875 12.5 8.75C12.9216 9.31398 13.5216 9.71875 14.2025 9.89849C14.8833'
          + '10.0782 15.6049 10.0224 16.25 9.74ZM8.75 17.5H6.25V12.5H8.75V17.5Z'
        }
        fill={systemColors.darkGrayColor}
      />
    </svg>
  );
}
