import React from 'react';
import systemColors from 'utils/constants/styles/system_colors';

export default function FinishedIcon() {
  return (
    <svg width="22" height="22" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d={
          'M12.5834 0.0834961C10.3732 0.0834961 8.25362 0.96147 6.69082 2.52427C5.12801 4.08708 4.25004'
          + ' 6.20669 4.25004 8.41683C4.25004 10.627 5.12801 12.7466 6.69082 14.3094C8.25362 15.8722 10.3732'
          + ' 16.7502 12.5834 16.7502C14.7935 16.7502 16.9131 15.8722 18.4759 14.3094C20.0387 12.7466 20.9167'
          + ' 10.627 20.9167 8.41683C20.9167 6.20669 20.0387 4.08708 18.4759 2.52427C16.9131 0.96147 14.7935'
          + ' 0.0834961 12.5834 0.0834961ZM3.13546 4.06266C1.20837 5.646 0.083374 8.01058 0.083374'
          + ' 10.5002C0.083374 12.7103 0.961348 14.8299 2.52415 16.3927C4.08695 17.9555 6.20657 18.8335'
          + ' 8.41671 18.8335C9.08337 18.8335 9.73962 18.7502 10.375 18.5939C8.54171 18.1877 6.85421 17.271'
          + ' 5.46879 16.0106C3.43754 14.9272 2.16671 12.8022 2.16671 10.5002C2.16671 10.1877 2.19796 9.88558'
          + ' 2.23962 9.57308C2.19796 9.18766 2.16671 8.80225 2.16671 8.41683C2.16671 6.91683 2.50004 5.42725'
          + ' 3.13546 4.06266ZM16.8438 4.3335L18.3125 5.81266L11.5417 12.5835L7.59379 8.63558L9.07296'
          + ' 7.15641L11.5417 9.63558'
        }
        fill={systemColors.defaultColor}
      />
    </svg>
  );
}
