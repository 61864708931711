import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import systemColors from 'utils/constants/styles/system_colors';

const SvgWrapper = styled.svg`
  fill: ${({ $color }) => $color || systemColors.inactiveColor};
`;

export default function DownloadIcon({ $color }) {
  return (
    <SvgWrapper
      $color={$color}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={
          'M5.20825 20.8333H19.7916V18.75H5.20825V20.8333ZM19.7916 9.375H15.6249V3.125H9.37492V9.375H5'
          + '.20825L12.4999 16.6667L19.7916 9.375Z'
        }
      />
    </SvgWrapper>
  );
}

DownloadIcon.propTypes = {
  $color: PropTypes.string,
};
