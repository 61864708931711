import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const SignatureInputCanvas = styled.canvas`
  width: 560px;
  height: 300px;
  background-color: white;

  border: 2px solid #00AFCA;
  border-radius: 10px;
`;
