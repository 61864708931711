import React from 'react';
import systemColors from 'utils/constants/styles/system_colors';

export default function GroupIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d={
          'M10 4.58301C10.7735 4.58301 11.5154 4.8903 12.0624 5.43728C12.6094 5.98426 12.9167 6.72613 '
          + '12.9167 7.49967C12.9167 8.27322 12.6094 9.01509 12.0624 9.56207C11.5154 10.109 10.7735 10.4163 '
          + '10 10.4163C9.22645 10.4163 8.48459 10.109 7.93761 9.56207C7.39062 9.01509 7.08333 8.27322 '
          + '7.08333 7.49967C7.08333 6.72613 7.39062 5.98426 7.93761 5.43728C8.48459 4.8903 9.22645 4.58301 '
          + '10 4.58301ZM4.16667 6.66634C4.63333 6.66634 5.06667 6.79134 5.44167 7.01634C5.31667 8.20801 '
          + '5.66667 9.39134 6.38333 10.3163C5.96667 11.1163 5.13333 11.6663 4.16667 11.6663C3.50363 11.6663 '
          + '2.86774 11.4029 2.3989 10.9341C1.93006 10.4653 1.66667 9.82938 1.66667 9.16634C1.66667 8.5033 '
          + '1.93006 7.86741 2.3989 7.39857C2.86774 6.92973 3.50363 6.66634 4.16667 6.66634ZM15.8333 '
          + '6.66634C16.4964 6.66634 17.1323 6.92973 17.6011 7.39857C18.0699 7.86741 18.3333 8.5033 '
          + '18.3333 9.16634C18.3333 9.82938 18.0699 10.4653 17.6011 10.9341C17.1323 11.4029 16.4964 11.6663 '
          + '15.8333 11.6663C14.8667 11.6663 14.0333 11.1163 13.6167 10.3163C14.3333 9.39134 14.6833 8.20801 '
          + '14.5583 7.01634C14.9333 6.79134 15.3667 6.66634 15.8333 6.66634ZM4.58333 15.208C4.58333 13.483 '
          + '7.00833 12.083 10 12.083C12.9917 12.083 15.4167 13.483 15.4167 15.208V16.6663H4.58333V15.208ZM0 '
          + '16.6663V15.4163C0 14.258 1.575 13.283 3.70833 12.9997C3.21667 13.5663 2.91667 14.3497 2.91667 '
          + '15.208V16.6663H0ZM20 16.6663H17.0833V15.208C17.0833 14.3497 16.7833 13.5663 16.2917 '
          + '12.9997C18.425 13.283 20 14.258 20 15.4163V16.6663Z'
        }
        fill={systemColors.darkGrayColor}
      />
    </svg>
  );
}
