import React, { lazy, Suspense, useCallback } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logOutUser } from 'store/user_auth/user_auth.reducers';
import CreateIssueButton from 'views/Issue/Shared/CreateIssueButton/CreateIssueButton';
import { LogOutIcon } from 'components/icons';
import Sidebar from './Sidebar/Sidebar';
import * as S from './ApplicationLayout.styles';

const ActivationModal = lazy(() => import('views/User/Activation/ActivationModal'));

export default function ApplicationLayout({ children }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isActive } = useSelector((state) => state.user);

  const logOut = useCallback(() => {
    dispatch(logOutUser()).then(() => history.push('/login'));
  }, [dispatch, history]);

  return (
    <S.LayoutWrapper>
      <S.LayoutHeaderWrapper>
        <CreateIssueButton />

        <S.HeaderIcons>
          <S.LogOutIconWrapper title={i18n.t('layouts.application.logout_button')} onClick={logOut}>
            <LogOutIcon />
          </S.LogOutIconWrapper>
        </S.HeaderIcons>
      </S.LayoutHeaderWrapper>

      <S.LayoutContentWrapper>
        <Sidebar />

        <S.ContentWrapper>
          { children }
        </S.ContentWrapper>
      </S.LayoutContentWrapper>

      <Suspense fallback={<></>}>
        { !isActive && <ActivationModal /> }
      </Suspense>
    </S.LayoutWrapper>
  );
}

ApplicationLayout.propTypes = {
  children: PropTypes.object,
};
