import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import systemColors from 'utils/constants/styles/system_colors';

const inputSizeMap = {
  medium: { height: 50 },
};

export const PickerWrapper = styled.div`
  .react-datepicker {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-shadow: -4px -4px 15px rgba(0, 0, 0, 0.15), 4px 4px 15px rgba(0, 0, 0, 0.15);

    background: white;
    border-radius: 10px;
    border: 0px;
  }

  .react-datepicker__navigation--previous {
    top: 20px;
    right: 48px;
    left: unset;
  }

  .react-datepicker__navigation--next {
    top: 20px;
    right: 16px;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__header {
    background: white;
    border-bottom: 0px;
    padding: 0 0 8px 0;

    .react-datepicker__day-names {
      margin-bottom: 0;
    }

    .react-datepicker__day-name {
      font-family: 'FiraSans';
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 0.2px;
      font-feature-settings: 'tnum' on, 'lnum' on;

      color: #13171E;
      width: 40px;
      height: 20px;
      margin: 0;
    }

    .react-datepicker__header__dropdown {
      height: 40px;
      text-align: left;

      font-family: 'FiraSans';
      font-weight: 500;
      font-size: 17px;
      line-height: 20px;
      letter-spacing: 0.01em;

      .react-datepicker__month-dropdown-container, .react-datepicker__year-dropdown-container {
        padding-right: 20px;

        .react-datepicker__month-dropdown {
          left: 0;
        }

        .react-datepicker__year-dropdown {
          width: 78px;
        }

        .react-datepicker__month-dropdown, .react-datepicker__year-dropdown {
          padding: 0px;
          background: white;
          border-radius: 10px;
          border: 0;
          box-shadow: -4px -4px 15px rgba(0, 0, 0, 0.15), 4px 4px 15px rgba(0, 0, 0, 0.15);

          max-height: 240px;
          overflow: auto;

          .react-datepicker__month-option, .react-datepicker__year-option {
            font-family: 'OpenSans';
            font-weight: 400;
            font-size: 16px;
            line-height: 135%;

            letter-spacing: 0.01em;
            padding: 9px 12px;

            &.react-datepicker__month-option--selected_month, &.react-datepicker__year-option--selected_year {
              .react-datepicker__month-option--selected, .react-datepicker__year-option--selected {
                display: none;
              }

              background #4DAD33;
              color: white;
            }

            &:hover {
              background: #EEF7EB;
              color: black;
            }
          }
        }

        .react-datepicker__month-read-view, .react-datepicker__year-read-view {
          visibility: visible !important;
        }

        .react-datepicker__month-read-view--down-arrow, .react-datepicker__year-read-view--down-arrow {
          top: 3px;
        }
      }

      select {
        border: none;
        background: white;

        &:focus-visible {
          border: none;
          background: white;
        }
      }
    }

    .react-datepicker__current-month {
      display: none;
    }
  }

  .react-datepicker__month {
    margin: 0;

    .react-datepicker__week {
      .react-datepicker__day {
        width: 40px;
        height: 40px;
        padding: 10px;
        border-radius: 100px;
        margin: 0;

        font-family: 'FiraSans';
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        font-feature-settings: 'tnum' on, 'lnum' on;

        &.react-datepicker__day--selected, &.react-datepicker__day--keyboard-selected {
          background: #4DAD33;
          color: #FFFFFF;
        }

        &.react-datepicker__day--outside-month {
          background: #F3F3F3;
        }
      }
    }
  }

  .react-datepicker__input-time-container {
    margin: 0;

    .react-datepicker-time__input {
      display: flex !important;
      align-items: center;
      padding-top: 20px;
      margin-left: 0 !important;

      input {
        box-sizing: border-box;
        height: 30px;
        color: #13171E;
        padding: 0 16px;
        background: white;

        border: 2px solid #13171E;
        border-radius: 10px;

        font-family: 'OpenSans';
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.01em;
        outline: none;
      }

      input[type="time"]::-webkit-calendar-picker-indicator {
        background: none;
        display: none;
      }
    }
  }
`;

export const StyledDatePicker = styled(DatePicker)`
  height: ${({ $size }) => inputSizeMap[$size]?.height || inputSizeMap.medium.height}px;
  width: 100%;
  border: 2px solid ${systemColors.darkGrayColor};
  box-sizing: border-box;
  border-radius: 10px;
  background: white;
  font-size: 20px;
  line-height: 27px;
  padding: 20px;
  outline: none;

  &:focus {
    border-color: ${systemColors.defaultColor};
  }
`;

export const ClockIconWrapper = styled.div`
  display: flex;
  padding-right: 10px;
`;

export const CalendarIconWrapper = styled.div`
  position: absolute;
  right: 17.5px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const ResetIconWrapper = styled.div`
  position: absolute;
  right: 50px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
