import React from 'react';
import PropTypes from 'prop-types';
import { LoadingIcon } from 'components/icons';
import * as S from './SpinnerLoader.styles';

// Realise spinner with text (bottom or right positioning)
export default function SpinnerLoader({
  width = 25, height = 26, rightText, bottomText,
}) {
  return (
    <S.SpinnerWrapper $withRightText={rightText} $withBottomText={bottomText}>
      <LoadingIcon width={width} height={height} />
      { rightText && <S.RightContent>{rightText}</S.RightContent> }
      { bottomText && <S.BottomContent>{bottomText}</S.BottomContent> }
    </S.SpinnerWrapper>
  );
}

SpinnerLoader.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  rightText: PropTypes.string,
  bottomText: PropTypes.string,
};
