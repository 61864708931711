import styled from 'styled-components';
import { Button } from 'antd';
import systemColors from 'utils/constants/styles/system_colors';

const defaultHeight = '67px';
const defaultWidth = '100%';

const SubmitButton = styled(Button)`
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${systemColors.defaultColor};
  color: #FFFFFF;
  border-radius: 10px;
  height: ${({ $height }) => ($height || defaultHeight)};
  width: ${({ $width }) => ($width || defaultWidth)};
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 20px;
  box-sizing: border-box;
  border: 5px solid ${systemColors.defaultColor};

  ${({ $withoutMargin }) => $withoutMargin && `
    margin: 0;
  `}

  &[disabled], &[disabled]:hover {
    background: ${systemColors.hoverColor};
    border-color: ${systemColors.hoverColor};
    color: #FFFFFF;
  }

  &:hover {
    color: #FFFFFF;
    background: ${systemColors.defaultColor};
    border-color: ${systemColors.hoverColor};
  }

  &:focus {
    color: #FFFFFF;
    background: ${systemColors.defaultColor};
    border-color: ${systemColors.defaultColor};
  }
`;

export default SubmitButton;
