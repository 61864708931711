import styled from 'styled-components';
import { Layout } from 'antd';

export const LayoutWrapper = styled(Layout)`
  background: rgba(77, 173, 51, 0.2);
  min-height: 100%;
`;

export const LayoutContentWrapper = styled(Layout.Content)`
  padding: 150px 20px;
  height: 100%;
  width: 100%;
`;
