import React from 'react';

export default function RefreshIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d={
          'M18.3853 6.61393C16.8748 5.10352 14.8019 4.16602 12.4998 4.16602C10.2897 4.16602 8.17008'
          + ' 5.04399 6.60728 6.60679C5.04448 8.1696 4.1665 10.2892 4.1665 12.4993C4.1665 14.7095'
          + ' 5.04448 16.8291 6.60728 18.3919C8.17008 19.9547 10.2897 20.8327 12.4998 20.8327C16.3853'
          + ' 20.8327 19.6248 18.1764 20.5519 14.5827H18.3853C17.5311 17.0098 15.2186 18.7493 12.4998'
          + ' 18.7493C10.8422 18.7493 9.25252 18.0909 8.08042 16.9188C6.90832 15.7467 6.24984 14.157'
          + ' 6.24984 12.4993C6.24984 10.8417 6.90832 9.25203 8.08042 8.07993C9.25252 6.90783 10.8422'
          + ' 6.24935 12.4998 6.24935C14.229 6.24935 15.7707 6.9681 16.8957 8.10352L13.5415'
          + ' 11.4577H20.8332V4.16602L18.3853 6.61393Z'
        }
        fill="white"
      />
    </svg>
  );
}
