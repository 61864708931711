import React from 'react';
import PropTypes from 'prop-types';

export default function SendIcon({ $height = 25, $width = 25 }) {
  return (
    <svg
      width={$width}
      height={$height}
      viewBox={`0 0 ${$width} ${$height}`}
      ill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1227_831)">
        <path
          d="M5.08301 20.2679L25.083 11.6964L5.08301 3.125V9.79167L19.3687 11.6964L5.08301 13.6012V20.2679Z"
          fill="#4DAD33"
        />
      </g>
      <defs>
        <clipPath id="clip0_1227_831">
          <rect width={$width} height={$height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

SendIcon.propTypes = {
  $height: PropTypes.number,
  $width: PropTypes.number,
};
