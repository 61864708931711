import React from 'react';
import PropTypes from 'prop-types';
import systemColors from 'utils/constants/styles/system_colors';

export default function ChevronLeftIcon({ $height = 25, $width = 25 }) {
  return (
    <svg
      width={$width}
      height={$height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={
          'M16.0522 17.2708L11.2814 12.5L16.0522 7.71875L14.5835 6.25L8.3335 12.5L14.5835 18.75L16.0522'
          + ' 17.2708Z'
        }
        fill={systemColors.inactiveColor}
      />
    </svg>
  );
}

ChevronLeftIcon.propTypes = {
  $height: PropTypes.number,
  $width: PropTypes.number,
};
