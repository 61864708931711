import React, { lazy, Suspense, useEffect } from 'react';
import { Switch, useHistory } from 'react-router-dom';
import { AuthRoute, PrivateRoute, ProtectedRoutes } from 'components/routing';
import { useSelector, useDispatch } from 'react-redux';
import { checkAuth } from 'store/user_auth/user_auth.reducers';
import { useRollbarPerson } from '@rollbar/react';
import { initInterceptors } from 'services/ApiRequests';

const AuthPage = lazy(() => import('views/Auth/AuthPage/AuthPage'));

export default function App() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id: currentUserId, isAuthorized, isCheckAuthInProcess } = useSelector((state) => state.user);
  initInterceptors(dispatch, isAuthorized, history);

  useRollbarPerson({ id: currentUserId });

  useEffect(() => {
    dispatch(checkAuth());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isCheckAuthInProcess) {
    return <div />;
  }

  return (
    <>
      <Suspense fallback={<div />}>
        <Switch>
          <AuthRoute path="/login" isAuthorized={isAuthorized}>
            <AuthPage />
          </AuthRoute>

          <PrivateRoute path="/" isAuthorized={isAuthorized}>
            <ProtectedRoutes />
          </PrivateRoute>
          {/* TODO: add error routes (404, 500, nomatch, etc.) */}
        </Switch>
      </Suspense>
    </>
  );
}
