import React from 'react';
import styled from 'styled-components';

const SvgWrapper = styled.svg`
  fill: ${({ $color }) => $color || '#898B8F'};
`;

export default function DotIcon({ $color }) {
  return (
    <SvgWrapper
      $color={$color}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={
          'M10.0001 6.66699C9.11603 6.66699 8.26818 7.01818 7.64306 7.6433C7.01794 8.26842 6.66675 9.11627 '
          + '6.66675 10.0003C6.66675 10.8844 7.01794 11.7322 7.64306 12.3573C8.26818 12.9825 9.11603 13.3337 '
          + '10.0001 13.3337C10.8841 13.3337 11.732 12.9825 12.3571 12.3573C12.9822 11.7322 13.3334 10.8844 '
          + '13.3334 10.0003C13.3334 9.11627 12.9822 8.26842 12.3571 7.6433C11.732 7.01818 10.8841 6.66699 '
          + '10.0001 6.66699Z'
        }
      />
    </SvgWrapper>
  );
}
