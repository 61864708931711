import React from 'react';
import PropTypes from 'prop-types';
import systemColors from 'utils/constants/styles/system_colors';

export default function AuthorIcon({ height = 40, width = 40 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#F3FBFD" />
      <path
        d={
          'M19.5001 12.167C20.6051 12.167 21.665 12.606 22.4464 13.3874C23.2278 14.1688 23.6667 15.2286 '
          + '23.6667 16.3337C23.6667 17.4387 23.2278 18.4985 22.4464 19.2799C21.665 20.0613 20.6051 20.5003 '
          + '19.5001 20.5003C18.395 20.5003 17.3352 20.0613 16.5538 19.2799C15.7724 18.4985 15.3334 17.4387 '
          + '15.3334 16.3337C15.3334 15.2286 15.7724 14.1688 16.5538 13.3874C17.3352 12.606 18.395 12.167 '
          + '19.5001 12.167ZM19.5001 22.5837C24.1042 22.5837 27.8334 24.4482 27.8334 '
          + '26.7503V28.8337H11.1667V26.7503C11.1667 24.4482 14.8959 22.5837 19.5001 22.5837Z'
        }
        fill={systemColors.defaultColor}
      />
    </svg>
  );
}

AuthorIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};
