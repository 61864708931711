import React from 'react';
import systemColors from 'utils/constants/styles/system_colors';

export default function PinIcon() {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 7.5V2.5H10.625V1.25H4.375V2.5H5V7.5L3.75 8.75V10H7V13.75H8V10H11.25V8.75L10 7.5Z"
        fill={systemColors.defaultColor}
      />
    </svg>
  );
}
