import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { CloseIcon } from 'components/icons';
import * as S from './FictiveInput.styles';

export default function FictiveInput({
  postfix, prefix, allowClear, isActive, children, handleClear, ...props
}) {
  const handleReset = useCallback(() => {
    if (handleClear) { handleClear(); }
  }, [handleClear]);

  return (
    <S.StyledFictiveInput
      $isActive={isActive}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <S.PrefixContainer>{prefix}</S.PrefixContainer>
      <S.InputContent>{children}</S.InputContent>

      <S.RightContentWrapper>
        {
          allowClear && children && (
            <S.ResetIconWrapper
              onClick={handleReset}
            >
              <CloseIcon />
            </S.ResetIconWrapper>
          )
        }
        <S.PostfixContainer>{postfix}</S.PostfixContainer>
      </S.RightContentWrapper>
    </S.StyledFictiveInput>
  );
}

FictiveInput.propTypes = {
  prefix: PropTypes.any,
  allowClear: PropTypes.bool,
  isActive: PropTypes.bool,
  postfix: PropTypes.any,
  children: PropTypes.string,
  handleClear: PropTypes.func,
};
