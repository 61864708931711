import { createSlice } from '@reduxjs/toolkit';
import {
  signUpUser, logInUser, checkAuth, logOutUser,
} from './user_auth.reducers';

const initialState = {
  id: null,
  isAuthorized: false,
  isActive: false,
  isCheckAuthInProcess: true,
};

/* eslint no-param-reassign: 0 */
export const userAuthSlice = createSlice({
  name: 'userAuthSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [signUpUser.fulfilled]: (state, { payload }) => {
      state.isAuthorized = true;
      state.id = payload.id;
      state.isActive = payload.isActive;
      state.isCheckAuthInProcess = false;
    },
    [signUpUser.rejected]: (state) => {
      state.isCheckAuthInProcess = false;
    },
    [logInUser.fulfilled]: (state, { payload }) => {
      state.isAuthorized = true;
      state.isCheckAuthInProcess = false;
      state.id = payload.id;
      state.isActive = payload.isActive;
    },
    [logInUser.rejected]: (state) => {
      state.isCheckAuthInProcess = false;
    },
    [logOutUser.fulfilled]: (state) => {
      state.isAuthorized = false;
      state.id = null;
    },
    [checkAuth.fulfilled]: (state, { payload }) => {
      state.isAuthorized = true;
      state.isCheckAuthInProcess = false;
      state.id = payload.id;
      state.isActive = payload.isActive;
    },
    [checkAuth.rejected]: (state) => {
      state.isCheckAuthInProcess = false;
      state.isAuthorized = false;
      state.id = null;
    },
  },
});
/* eslint no-param-reassign: 1 */

export const userSelector = (state) => state.user;
