import styled from 'styled-components';

const SpinnerWrapper = styled.div`
  display: flex;
  ${({ $withRightText }) => ($withRightText && `
    flex-direction: row;
    align-items: center;
  `)}
  ${({ $withBottomText }) => ($withBottomText && `
    flex-direction: column;
  `)}
`;

const RightContent = styled.div`
  padding-left: 10px;
`;

const BottomContent = styled.div`
  text-align: center;
`;

export { SpinnerWrapper, RightContent, BottomContent };
