import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import ApplicationLayout from 'components/layouts/ApplicationLayout/ApplicationLayout';

function PrivateRoute({ children, isAuthorized, ...rest }) {
  return (
    <ApplicationLayout>
      <Route
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        render={
          ({ location }) => (
            isAuthorized
              ? (
                children
              ) : (
                <Redirect
                  to={{
                    pathname: '/login',
                    state: { from: location },
                  }}
                />
              ))
        }
      />
    </ApplicationLayout>
  );
}

export default PrivateRoute;

PrivateRoute.propTypes = {
  children: PropTypes.object,
  isAuthorized: PropTypes.bool,
};
