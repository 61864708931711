import React from 'react';
import PropTypes from 'prop-types';
import { EditIcon } from 'components/icons';
import styled from 'styled-components';
import systemColors from 'utils/constants/styles/system_colors';

const ButtonWrapper = styled.div`
  background: ${({ $bgColor }) => ($bgColor || systemColors.bgColor)};
  border-radius: 100px;
  width: ${({ $width }) => `${$width}px;`}
  height: ${({ $height }) => `${$height}px;`}
  position: relative;
  box-sizing: border-box;
  border: ${({ $borderSize, $bgColor }) => `${$borderSize}px solid ${($bgColor || systemColors.bgColor)}`};
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    border: ${({ $borderSize }) => `${$borderSize}px`} solid ${systemColors.hoverColor};
  }
`;

const sizeMap = {
  medium: {
    height: 40, width: 40, borderSize: 3, iconHeight: 20, iconWidth: 20,
  },
  small: {
    height: 25, width: 25, borderSize: 1.5, iconHeight: 12.5, iconWidth: 12.5,
  },
};

export default function EditButton({
  $bgColor, $size = 'medium', onClick,
}) {
  const sizeOptions = sizeMap[$size];

  return (
    <ButtonWrapper
      $bgColor={$bgColor}
      $height={sizeOptions.height}
      $width={sizeOptions.width}
      $borderSize={sizeOptions.borderSize}
      onClick={onClick}
    >
      <EditIcon $height={sizeOptions.iconHeight} $width={sizeOptions.iconWidth} />
    </ButtonWrapper>
  );
}

EditButton.propTypes = {
  onClick: PropTypes.func,
  $bgColor: PropTypes.string,
  $size: PropTypes.string,
};
