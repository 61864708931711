import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import systemColors from 'utils/constants/styles/system_colors';

const ButtonWrapper = styled.div`
  background: ${systemColors.bgColor};
  border-radius: 100px;
  width: ${({ $width }) => `${$width}px;`}
  height: ${({ $height }) => `${$height}px;`}
  position: relative;
  box-sizing: border-box;
  border: ${({ $borderSize }) => `${$borderSize}px`} solid ${systemColors.bgColor};
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ $backgroundColor }) => $backgroundColor && `
    border-color: ${$backgroundColor};
    background: ${$backgroundColor};
  `}

  &:hover {
    cursor: pointer;
    border: ${({ $borderSize }) => `${$borderSize}px`} solid ${systemColors.hoverColor};
  }
`;

const sizeMap = {
  large: {
    height: 52, width: 52, borderSize: 3, iconHeight: 32, iconWidth: 32,
  },
  medium: {
    height: 40, width: 40, borderSize: 3, iconHeight: 25, iconWidth: 25,
  },
  small: {
    height: 25, width: 25, borderSize: 1.5, iconHeight: 17.5, iconWidth: 17.5,
  },
};

export default function ButtonWithSvgIcon({
  $backgroundColor, title, $size = 'medium', icon, onClick,
}) {
  const sizeOptions = sizeMap[$size];

  return (
    <ButtonWrapper
      $backgroundColor={$backgroundColor}
      $height={sizeOptions.height}
      $width={sizeOptions.width}
      $borderSize={sizeOptions.borderSize}
      title={title}
      onClick={onClick}
    >
      <icon.type
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...icon.props}
        {...{ $height: sizeOptions.iconHeight, $width: sizeOptions.iconWidth }}
      />
    </ButtonWrapper>
  );
}

ButtonWithSvgIcon.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.any,
  $backgroundColor: PropTypes.string,
  $size: PropTypes.string,
  onClick: PropTypes.func,
};
