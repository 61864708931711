import React, {
  useCallback, useState, useEffect, useRef, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { debounce } from 'lodash';
import { getRequest } from 'services/ApiRequests';
import { SpinnerLoader } from 'components/loaders';
import { CloseIcon, CheckIcon } from 'components/icons';
import * as S from './AsyncSelect.styles';

const { Option, OptGroup } = S.StyledSelect;

// Realise async custom select with search
export default function AsyncSelect({
  closedByDefault, loadedByDefault, unloadedValueText, collectionPath, collectionParams, isGroupped,
  optionPresenter, ...props
}) {
  const [isOpened, setIsOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const wasInited = useRef(false);
  const wasOpened = useRef(false);
  const currentSearchValue = useRef('');
  const currentPage = useRef(1);
  const isLastPage = useRef(false);

  const optionBuilder = useCallback((item) => (
    <Option key={item.value} value={item.value} title={item.label} label={item.label}>
      <S.OptionWrapper>
        <S.OptionIsSelectedContainer>
          {
            (props.value === item.value || props.defaultValue === item.value)
              && <CheckIcon $width={20} $height={20} />
          }
        </S.OptionIsSelectedContainer>

        <S.OptionLabel title={item.label}>
          { optionPresenter ? optionPresenter(item) : item.label }
        </S.OptionLabel>
      </S.OptionWrapper>
    </Option>
  ), [props.value, props.defaultValue, optionPresenter]);

  const buildSelectOptions = useCallback(() => {
    if (!options || options.length < 1) { return []; }

    if (isGroupped) {
      return (
        options.map((group, i) => {
          if (!group.items || group.items.length < 1) { return null; }

          return (
            // eslint-disable-next-line react/no-array-index-key
            <OptGroup key={i} label={group.label}>
              { group.items.map((item) => optionBuilder(item)) }
            </OptGroup>
          );
        })
      );
    }

    return options.map((item) => optionBuilder(item));
  }, [isGroupped, options, optionBuilder]);

  const loadCollection = useCallback(async ({ search = '' } = {}) => {
    setIsLoading(true);
    const requestParams = { ...collectionParams, page: currentPage.current };
    if (search.length > 0) { requestParams.search = search; }

    await getRequest(collectionPath, { params: requestParams })
      .then((response) => {
        wasInited.current = true;
        wasOpened.current = true;
        isLastPage.current = response.data.isLastPage;
        if (currentPage.current > 1) {
          setOptions((currentOptions) => ([...currentOptions, ...response.data.collection]));
        } else {
          setOptions(response.data.collection);
        }
        setIsLoading(false);
      }).catch(() => {});
  }, [collectionPath, collectionParams]);

  const loadCollectionOnSearch = useCallback((value) => {
    if (value.length < 2 && currentSearchValue.current === '') { return; }
    currentSearchValue.current = value;
    currentPage.current = 1;
    isLastPage.current = false;

    loadCollection({ search: value });
  }, [loadCollection]);

  const debouncedLoadCollectionOnSearch = useMemo(() => (
    debounce(loadCollectionOnSearch, 600)
  ), [loadCollectionOnSearch]);

  const onSearch = useCallback((value) => {
    setSearchValue(value);
    debouncedLoadCollectionOnSearch(value);
  }, [debouncedLoadCollectionOnSearch]);

  useEffect(() => {
    if (!closedByDefault) { setIsOpened(true); }
    if (loadedByDefault) { loadCollection(); }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    currentPage.current = 1;
    isLastPage.current = false;
    wasOpened.current = false;
  }, [collectionPath, collectionParams]);

  const onDropdownVisibleChange = useCallback((open) => {
    if (open && !wasOpened.current) {
      setIsLoading(true);
      loadCollection();
    }

    setIsOpened(open);
  }, [loadCollection]);

  const onPopupScroll = useCallback(({ target }) => {
    if (isLastPage.current || isLoading) { return; }

    const pixelsToScroll = 50;
    if ((target.scrollTop + target.clientHeight + pixelsToScroll) >= target.scrollHeight) {
      currentPage.current += 1;
      loadCollection({ search: searchValue });
    }
  }, [searchValue, isLoading, loadCollection]);

  const selectedValue = useMemo(() => {
    let foundOption = null;
    if (isGroupped) {
      foundOption = options.find((option) => option.items.find((item) => item.value === props.defaultValue));
    } else {
      foundOption = options.find((option) => option.value === props.defaultValue);
    }

    return foundOption ? props.defaultValue : unloadedValueText;
  }, [isGroupped, options, unloadedValueText, props.defaultValue]);

  return (
    <>
      <S.StyledSelect
        open={isOpened}
        loading={isLoading}
        onSearch={props.showSearch && onSearch}
        searchValue={searchValue}
        filterOption={false}
        optionLabelProp="label"
        clearIcon={<CloseIcon />}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        value={selectedValue}
        bordered={false}
        notFoundContent={props.notFoundContent || <S.NotFound>{i18n.t('not_found')}</S.NotFound>}
        dropdownClassName={`custom-select-dropdown ${props.size}`}
        onDropdownVisibleChange={onDropdownVisibleChange}
        onPopupScroll={onPopupScroll}
        dropdownRender={(menu) => (
          <>
            {menu}
            { isLoading && <S.LoaderWrapper><SpinnerLoader /></S.LoaderWrapper> }
          </>
        )}
      >
        {buildSelectOptions()}
      </S.StyledSelect>
    </>
  );
}

AsyncSelect.propTypes = {
  closedByDefault: PropTypes.bool,
  loadedByDefault: PropTypes.bool,
  unloadedValueText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.string,
  collectionPath: PropTypes.string,
  collectionParams: PropTypes.object,
  isGroupped: PropTypes.bool,
  showSearch: PropTypes.bool,
  optionPresenter: PropTypes.func,
  notFoundContent: PropTypes.any,
};
