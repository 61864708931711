import React from 'react';
import systemColors from 'utils/constants/styles/system_colors';

export default function ActiveIcon() {
  return (
    <svg width="22" height="23" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d={
          'M14.5833 3.125C12.3731 3.125 10.2535 4.00297 8.6907 5.56578C7.12789 7.12858 6.24992 9.2482'
          + ' 6.24992 11.4583C6.24992 13.6685 7.12789 15.7881 8.6907 17.3509C10.2535 18.9137 12.3731'
          + ' 19.7917 14.5833 19.7917C16.7934 19.7917 18.913 18.9137 20.4758 17.3509C22.0386 15.7881'
          + ' 22.9166 13.6685 22.9166 11.4583C22.9166 9.2482 22.0386 7.12858 20.4758 5.56578C18.913 4.00297'
          + ' 16.7934 3.125 14.5833 3.125ZM5.13534 7.10417C3.20825 8.6875 2.08325 11.0521 2.08325'
          + ' 13.5417C2.08325 15.7518 2.96123 17.8714 4.52403 19.4342C6.08683 20.997 8.20645 21.875 10.4166'
          + ' 21.875C11.0833 21.875 11.7395 21.7917 12.3749 21.6354C10.5416 21.2292 8.85409 20.3125 7.46867'
          + ' 19.0521C5.43742 17.9688 4.16659 15.8438 4.16659 13.5417C4.16659 13.2292 4.19784 12.9271'
          + ' 4.2395 12.6146C4.19784 12.2292 4.16659 11.8438 4.16659 11.4583C4.16659 9.95833 4.49992'
          + '8.46875 5.13534 7.10417Z'
        }
        fill={systemColors.defaultColor}
      />
    </svg>
  );
}
