import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import systemColors from 'utils/constants/styles/system_colors';

const IconSvg = styled.svg`
  animation: rotation 3s infinite linear;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

export default function LoadingIcon({ width = 25, height = 26 }) {
  return (
    <IconSvg width={width} height={height} viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4_558)">
        <path
          d={
            'M9.375 3.62534C9.36559 3.20918 9.43941 2.79534 9.59214 2.40811C9.74487 2.02088 9.97342 1.66807'
            + ' 10.2644 1.37038C10.5553 1.07269 10.9028 0.836136 11.2865 0.674597C11.6701 0.513058 12.0822'
            + ' 0.429792 12.4984 0.429688C12.9147 0.429584 13.3268 0.512643 13.7105 0.673991C14.0942'
            + ' 0.835338 14.4418 1.07172 14.7329 1.36926C15.024 1.6668 15.2528 2.0195 15.4057'
            + ' 2.40666C15.5586 2.79381 15.6326 3.20762 15.6234 3.62377C15.6054 4.44028 15.2684 5.21727'
            + ' 14.6846 5.78842C14.1009 6.35956 13.3167 6.67948 12.5 6.67969C11.6833 6.67989 10.899'
            + ' 6.36037 10.3149 5.78951C9.73083 5.21865 9.39346 4.44183 9.375 3.62534ZM16.0047'
            + ' 6.37065C15.9953 5.95449 16.0691 5.54065 16.2218 5.15342C16.3746 4.76619 16.6031'
            + ' 4.41338 16.8941 4.11569C17.185 3.81801 17.5325 3.58145 17.9162 3.41991C18.2998 3.25837'
            + ' 18.7118 3.1751 19.1281 3.175C19.5444 3.1749 19.9565 3.25796 20.3402 3.4193C20.7239 3.58065'
            + ' 21.0715 3.81703 21.3626 4.11457C21.6537 4.41212 21.8825 4.76482 22.0354 5.15197C22.1883'
            + ' 5.53912 22.2623 5.95293 22.2531 6.36909C22.2351 7.18559 21.8981 7.96258 21.3143'
            + ' 8.53373C20.7305 9.10488 19.9464 9.4248 19.1297 9.425C18.313 9.4252 17.5286 9.10568'
            + ' 16.9446 8.53482C16.3605 7.96396 16.0232 7.18714 16.0047 6.37065ZM20.3125 13.0003C20.3125'
            + ' 12.5859 20.4771 12.1885 20.7701 11.8955C21.0632 11.6025 21.4606 11.4378 21.875'
            + ' 11.4378C22.2894 11.4378 22.6868 11.6025 22.9799 11.8955C23.2729 12.1885 23.4375 12.5859'
            + ' 23.4375 13.0003C23.4375 13.4147 23.2729 13.8122 22.9799 14.1052C22.6868 14.3982 22.2894'
            + ' 14.5628 21.875 14.5628C21.4606 14.5628 21.0632 14.3982 20.7701 14.1052C20.4771 13.8122'
            + ' 20.3125 13.4147 20.3125 13.0003ZM17.5672 19.63C17.5672 19.2156 17.7318 18.8182 18.0248'
            + ' 18.5252C18.3179 18.2321 18.7153 18.0675 19.1297 18.0675C19.5441 18.0675 19.9415 18.2321'
            + ' 20.2345 18.5252C20.5276 18.8182 20.6922 19.2156 20.6922 19.63C20.6922 20.0444 20.5276'
            + ' 20.4419 20.2345 20.7349C19.9415 21.0279 19.5441 21.1925 19.1297 21.1925C18.7153 21.1925'
            + ' 18.3179 21.0279 18.0248 20.7349C17.7318 20.4419 17.5672 20.0444 17.5672 19.63ZM10.9375'
            + ' 22.3753C10.9375 21.9609 11.1021 21.5635 11.3951 21.2705C11.6882 20.9775 12.0856 20.8128'
            + ' 12.5 20.8128C12.9144 20.8128 13.3118 20.9775 13.6049 21.2705C13.8979 21.5635 14.0625 21.9609'
            + ' 14.0625 22.3753C14.0625 22.7897 13.8979 23.1872 13.6049 23.4802C13.3118 23.7732 12.9144'
            + ' 23.9378 12.5 23.9378C12.0856 23.9378 11.6882 23.7732 11.3951 23.4802C11.1021 23.1872 10.9375'
            + ' 22.7897 10.9375 22.3753ZM4.30781 19.63C4.30781 19.2156 4.47243 18.8182 4.76546'
            + ' 18.5252C5.05848 18.2321 5.45591 18.0675 5.87031 18.0675C6.28471 18.0675 6.68214 18.2321'
            + ' 6.97517 18.5252C7.26819 18.8182 7.43281 19.2156 7.43281 19.63C7.43281 20.0444 7.26819'
            + ' 20.4419 6.97517 20.7349C6.68214 21.0279 6.28471 21.1925 5.87031 21.1925C5.45591 21.1925'
            + ' 5.05848 21.0279 4.76546 20.7349C4.47243 20.4419 4.30781 20.0444 4.30781 19.63ZM3.52656'
            + ' 6.37065C3.52656 5.74905 3.77349 5.15291 4.21303 4.71337C4.65257 4.27383 5.24871 4.0269'
            + ' 5.87031 4.0269C6.49191 4.0269 7.08806 4.27383 7.52759 4.71337C7.96713 5.15291 8.21406 5.74905'
            + ' 8.21406 6.37065C8.21406 6.99225 7.96713 7.58839 7.52759 8.02793C7.08806 8.46747 6.49191'
            + ' 8.7144 5.87031 8.7144C5.24871 8.7144 4.65257 8.46747 4.21303 8.02793C3.77349 7.58839 3.52656'
            + ' 6.99225 3.52656 6.37065ZM1.36719 13.0003C1.36719 12.5341 1.55239 12.087 1.88204'
            + ' 11.7574C2.21169 11.4277 2.6588 11.2425 3.125 11.2425C3.5912 11.2425 4.03831 11.4277 4.36796'
            + ' 11.7574C4.69761 12.087 4.88281 12.5341 4.88281 13.0003C4.88281 13.4665 4.69761 13.9136'
            + ' 4.36796 14.2433C4.03831 14.573 3.5912 14.7581 3.125 14.7581C2.6588 14.7581 2.21169 14.573'
            + ' 1.88204 14.2433C1.55239 13.9136 1.36719 13.4665 1.36719 13.0003Z'
          }
          fill={systemColors.defaultColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_4_558">
          <rect width="25" height="25" fill="#FFFFFF" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </IconSvg>
  );
}

LoadingIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
