import React from 'react';
import systemColors from 'utils/constants/styles/system_colors';

export default function CompanyIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d={
          'M5.60001 3.2002V16.8002H9.37778V14.1558H10.637V16.8002H14.4148V3.2002H5.60001ZM6.85927 '
          + '4.71131H8.11852V6.22242H6.85927V4.71131ZM9.37778 '
          + '4.71131H10.637V6.22242H9.37778V4.71131ZM11.8963 '
          + '4.71131H13.1556V6.22242H11.8963V4.71131ZM6.85927 '
          + '7.73353H8.11852V9.24464H6.85927V7.73353ZM9.37778 '
          + '7.73353H10.637V9.24464H9.37778V7.73353ZM11.8963 '
          + '7.73353H13.1556V9.24464H11.8963V7.73353ZM6.85927 '
          + '10.7558H8.11852V12.2669H6.85927V10.7558ZM9.37778 '
          + '10.7558H10.637V12.2669H9.37778V10.7558ZM11.8963 '
          + '10.7558H13.1556V12.2669H11.8963V10.7558ZM6.85927 '
          + '13.778H8.11852V15.2891H6.85927V13.778ZM11.8963 '
          + '13.778H13.1556V15.2891H11.8963V13.778Z'
        }
        fill={systemColors.darkGrayColor}
      />
    </svg>
  );
}
