import React, { lazy, Suspense } from 'react';
import i18n from 'i18next';
import { SubmitButton } from 'components/forms';
import useModal from 'hooks/feedback/useModal';

const CreateModal = lazy(() => import('./CreateModal/CreateModal'));

export default function CreateIssueButton() {
  const [isModalVisible, showModal, hideModal] = useModal(false);

  return (
    <>
      <SubmitButton $withoutMargin $width="255px" $height="50px" onClick={showModal}>
        {i18n.t('new_issue_button')}
      </SubmitButton>

      <Suspense fallback={<></>}>
        {
          isModalVisible && <CreateModal hideModal={hideModal} />
        }
      </Suspense>
    </>
  );
}
