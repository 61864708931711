import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import systemColors from 'utils/constants/styles/system_colors';

const IconWrapper = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #13171E;

  cursor: pointer;
  display: flex;
  align-items: center;
`;

export default function LoadFilesIcon({ count }) {
  return (
    <IconWrapper>
      {`+${count}`}
      <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d={
            'M8.125 6.125V2.6875L11.5625 6.125H8.125ZM3.75 1.75C3.05625 1.75 2.5 2.30625 2.5 3V13C2.5'
            + ' 13.3315 2.6317 13.6495 2.86612 13.8839C3.10054 14.1183 3.41848 14.25 3.75 14.25H11.25C11.5815'
            + ' 14.25 11.8995 14.1183 12.1339 13.8839C12.3683 13.6495 12.5 13.3315 12.5 13V5.5L8.75'
            + ' 1.75H3.75Z'
          }
          fill={systemColors.darkGrayColor}
        />
      </svg>
    </IconWrapper>
  );
}

LoadFilesIcon.propTypes = {
  count: PropTypes.number,
};
