import { createAsyncThunk } from '@reduxjs/toolkit';
import { postRequest, deleteRequest } from 'services/ApiRequests';
import axios from 'axios';

function actualizeCsrfToken(response) {
  if (response?.data?.csrfToken) {
    document.querySelector('meta[name="csrf-token"]').setAttribute('content', response.data.csrfToken);
  }
}

export const signUpUser = createAsyncThunk(
  'userAuthSlice/signUpUser',
  async ({ phone, verification_token, remember_me }, thunkAPI) => {
    try {
      const response = await postRequest(window.Routes.signupPath(), {
        user: { phone, verification_token, remember_me },
      });

      if (response.status === 200) {
        actualizeCsrfToken(response);
        const { active, id } = response.data.user;

        return { isActive: active, id };
      }

      return thunkAPI.rejectWithValue(response.data);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const logInUser = createAsyncThunk(
  'userAuthSlice/logInUser',
  async ({ phone, verification_token, remember_me }, thunkAPI) => {
    try {
      const response = await postRequest(window.Routes.smsLoginPath(), {
        user: { phone, verification_token, remember_me },
      });

      if (response.status === 200) {
        actualizeCsrfToken(response);
        const { active, id } = response.data.user;

        return { isActive: active, id };
      }

      return thunkAPI.rejectWithValue(response.data);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const checkAuth = createAsyncThunk(
  'userAuthSlice/checkAuth',
  async (_payload, thunkAPI) => {
    try {
      const response = await axios.get(window.Routes.checkSessionPath(), { withCredentials: true });

      if (response.status === 200) {
        const { active, id } = response.data.user;

        return { isActive: active, id };
      }

      return thunkAPI.rejectWithValue({});
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const logOutUser = createAsyncThunk(
  'userAuthSlice/logOutUser',
  async (_payload, thunkAPI) => {
    try {
      const response = await deleteRequest(window.Routes.logoutPath(), { withCredentials: true });

      if (response.status === 200) {
        actualizeCsrfToken(response);
        return {};
      }
      return thunkAPI.rejectWithValue({});
    } catch (e) {
      return thunkAPI.rejectWithValue({});
    }
  },
);
