import styled from 'styled-components';
import { Input as AntdInput } from 'antd';
import systemColors from 'utils/constants/styles/system_colors';

const sizeMap = {
  large: { height: 67 },
  medium: { height: 52 },
};

export const StyledInput = styled(AntdInput)`
  height: ${({ $size }) => sizeMap[$size]?.height || sizeMap.medium.height}px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  border: 2px solid ${systemColors.darkGrayColor};
  box-sizing: border-box;
  border-radius: 10px;
  background: #FFFFFF;
  font-size: 20px;
  line-height: 27px;

  ${({ $allowClear }) => $allowClear && `
    padding-right: 40px;
  `}
`;

export const ResetIconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 15px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
