import { lazy } from 'react';

const routes = [
  {
    path: '/contractors',
    component: lazy(() => import('views/Contractor/ListPage/ListPage')),
    exact: true,
  },
  {
    path: ['/', '/issues'],
    component: lazy(() => import('views/Issue/ListPage/ListPage')),
    exact: true,
  },
  {
    path: '/contractors/:contractorId/issues/:issueId',
    component: lazy(() => import('views/Issue/CardPage/CardPage')),
    exact: true,
  },
];

export default routes;
