import React from 'react';
import systemColors from 'utils/constants/styles/system_colors';
import styled from 'styled-components';

const SvgWrapper = styled.svg`
  cursor: pointer;
  fill: ${systemColors.darkGrayColor};

  &:hover {
    fill: ${systemColors.defaultColor}
  }
`;

export default function LogOutIcon() {
  return (
    <SvgWrapper
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={
          'M17.3333 18.209V15.084H10.0416V10.9173H17.3333V7.79232L22.5416 13.0007L17.3333 18.209ZM15.25'
          + ' 2.58398C15.8025 2.58398 16.3324 2.80348 16.7231 3.19418C17.1138 3.58488 17.3333 4.11478 17.3333'
          + ' 4.66732V6.75065H15.25V4.66732H5.87496V21.334H15.25V19.2507H17.3333V21.334C17.3333 21.8865'
          + ' 17.1138 22.4164 16.7231 22.8071C16.3324 23.1978 15.8025 23.4173 15.25 23.4173H5.87496C5.32242'
          + ' 23.4173 4.79252 23.1978 4.40182 22.8071C4.01112 22.4164 3.79163 21.8865 3.79163'
          + ' 21.334V4.66732C3.79163 4.11478 4.01112 3.58488 4.40182 3.19418C4.79252 2.80348 5.32242 2.58398'
          + ' 5.87496 2.58398H15.25Z'
        }
      />
    </SvgWrapper>
  );
}
