import React from 'react';
import systemColors from 'utils/constants/styles/system_colors';

export default function UserIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d={
          'M10 3.33301C10.8841 3.33301 11.7319 3.6842 12.357 4.30932C12.9822 4.93444 13.3333 5.78229 '
          + '13.3333 6.66634C13.3333 7.5504 12.9822 8.39824 12.357 9.02336C11.7319 9.64848 10.8841 9.99967 '
          + '10 9.99967C9.11595 9.99967 8.26811 9.64848 7.64299 9.02336C7.01787 8.39824 6.66668 7.5504 '
          + '6.66668 6.66634C6.66668 5.78229 7.01787 4.93444 7.64299 4.30932C8.26811 3.6842 9.11595 3.33301 '
          + '10 3.33301ZM10 11.6663C13.6833 11.6663 16.6667 13.158 16.6667 '
          + '14.9997V16.6663H3.33334V14.9997C3.33334 13.158 6.31668 11.6663 10 11.6663Z'
        }
        fill={systemColors.darkGrayColor}
      />
    </svg>
  );
}
