import React from 'react';
import AuthLayout from 'components/layouts/AuthLayout/AuthLayout';
import { Route, Redirect } from 'react-router-dom';

function AuthRoute({ children, isAuthorized, ...rest }) {
  return (
    <AuthLayout>
      <Route
        {...rest}
        render={
          ({ location }) => (
            !isAuthorized ? (
              children
            ) : (
              <Redirect
                to={{
                  pathname: '/',
                  state: { from: location },
                }}
              />
            ))
        }
      />
    </AuthLayout>
  );
}

export default AuthRoute;
