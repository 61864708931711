import React, { useCallback } from 'react';
import i18n from 'i18next';
import { NavLink } from 'react-router-dom';
import { EntityIcons } from 'components/icons';
import * as S from '../Sidebar.styles';

export default function ContractorsLink() {
  const calcIsContractorsActive = useCallback((match) => {
    if (match?.isExact) { return true; }
    return false;
  }, []);

  return (
    <NavLink to="/contractors" activeClassName="active-sidebar-item" isActive={calcIsContractorsActive}>
      <S.SidebarItem title={i18n.t('layouts.application.contractors_link')}>
        <EntityIcons.ContractorIcon $width={40} $height={41} />
        {i18n.t('layouts.application.contractors_link')}
      </S.SidebarItem>
    </NavLink>
  );
}
