import React, { useCallback } from 'react';
import i18n from 'i18next';
import { ChevronIcons } from 'components/icons';
import IssuesLink from './Links/IssuesLink';
import ContractorsLink from './Links/ContractorsLink';
import * as S from './Sidebar.styles';

export default function Sidebar() {
  const scrollPageToTop = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <S.SidebarWrapper>
      <IssuesLink />
      <ContractorsLink />

      <S.SidebarBottomBlock>
        <ChevronIcons.ChevronDoubleUpIcon
          title={i18n.t('layouts.application.scroll_top')}
          onClick={scrollPageToTop}
        />
      </S.SidebarBottomBlock>
    </S.SidebarWrapper>
  );
}
