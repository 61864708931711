import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import * as S from './Rate.styles';

const RATE_VALUE = 'normal';

export default function NormalRate({ $color, $size = 40, onClick }) {
  const handleClick = useCallback((e) => {
    if (onClick) { onClick(e, RATE_VALUE); }
  }, [onClick]);

  return (
    <S.SvgWrapper
      $color={$color}
      width={$size}
      height={$size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={handleClick}
    >
      <path
        d={
          'M19.9999 3.33301C17.8112 3.33301 15.644 3.7641 13.6219 4.60168C11.5998 5.43926 9.76245 6.66692 '
          + '8.21481 8.21456C5.0892 11.3402 3.33325 15.5794 3.33325 19.9997C3.33325 24.42 5.0892 28.6592 '
          + '8.21481 31.7848C9.76245 33.3324 11.5998 34.5601 13.6219 35.3977C15.644 36.2352 17.8112 36.6663 '
          + '19.9999 36.6663C24.4202 36.6663 28.6594 34.9104 31.785 31.7848C34.9106 28.6592 36.6666 24.42 '
          + '36.6666 19.9997C36.6666 17.811 36.2355 15.6437 35.3979 13.6216C34.5603 11.5995 33.3327 9.7622 '
          + '31.785 8.21456C30.2374 6.66692 28.4001 5.43926 26.378 4.60168C24.3559 3.7641 22.1886 3.33301 '
          + '19.9999 3.33301ZM19.9999 33.333C16.4637 33.333 13.0723 31.9283 10.5718 29.4278C8.07134 26.9273 '
          + '6.66659 23.5359 6.66659 19.9997C6.66659 16.4635 8.07134 13.0721 10.5718 10.5716C13.0723 8.0711 '
          + '16.4637 6.66634 19.9999 6.66634C23.5361 6.66634 26.9275 8.0711 29.428 10.5716C31.9285 13.0721 '
          + '33.3333 16.4635 33.3333 19.9997C33.3333 23.5359 31.9285 26.9273 29.428 29.4278C26.9275 31.9283 '
          + '23.5361 33.333 19.9999 33.333ZM14.1666 18.333C13.5035 18.333 12.8677 18.0696 12.3988 '
          + '17.6008C11.93 17.1319 11.6666 16.496 11.6666 15.833C11.6666 15.17 11.93 14.5341 12.3988 '
          + '14.0652C12.8677 13.5964 13.5035 13.333 14.1666 13.333C14.8296 13.333 15.4655 13.5964 15.9344 '
          + '14.0652C16.4032 14.5341 16.6666 15.17 16.6666 15.833C16.6666 16.496 16.4032 17.1319 15.9344 '
          + '17.6008C15.4655 18.0696 14.8296 18.333 14.1666 18.333ZM28.3333 15.833C28.3333 16.496 28.0699 '
          + '17.1319 27.601 17.6008C27.1322 18.0696 26.4963 18.333 25.8333 18.333C25.1702 18.333 24.5343 '
          + '18.0696 24.0655 17.6008C23.5966 17.1319 23.3333 16.496 23.3333 15.833C23.3333 15.17 23.5966 '
          + '14.5341 24.0655 14.0652C24.5343 13.5964 25.1702 13.333 25.8333 13.333C26.4963 13.333 27.1322 '
          + '13.5964 27.601 14.0652C28.0699 14.5341 28.3333 15.17 28.3333 15.833ZM26.6666 '
          + '23.333V26.6663H13.3333V23.333H26.6666Z'
        }
      />
    </S.SvgWrapper>
  );
}

NormalRate.propTypes = {
  $color: PropTypes.string,
  $size: PropTypes.number,
  onClick: PropTypes.func,
};
