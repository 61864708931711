import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import * as S from './Rate.styles';

const RATE_VALUE = 'good';

export default function GoodRate({ $color, $size = 40, onClick }) {
  const handleClick = useCallback((e) => {
    if (onClick) { onClick(e, RATE_VALUE); }
  }, [onClick]);

  return (
    <S.SvgWrapper
      $color={$color}
      width={$size}
      height={$size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={handleClick}
    >
      <path
        d={
          'M20 3.33301C10.7833 3.33301 3.33334 10.783 3.33334 19.9997C3.33334 29.2163 10.7833 36.6663 20 '
          + '36.6663C24.4203 36.6663 28.6595 34.9104 31.7851 31.7848C34.9107 28.6592 36.6667 24.42 36.6667 '
          + '19.9997C36.6667 10.783 29.1667 3.33301 20 3.33301ZM20 33.333C16.4638 33.333 13.0724 31.9283 '
          + '10.5719 29.4278C8.07143 26.9273 6.66668 23.5359 6.66668 19.9997C6.66668 16.4635 8.07143 13.0721 '
          + '10.5719 10.5716C13.0724 8.0711 16.4638 6.66634 20 6.66634C23.5362 6.66634 26.9276 8.0711 '
          + '29.4281 10.5716C31.9286 13.0721 33.3333 16.4635 33.3333 19.9997C33.3333 23.5359 31.9286 26.9273 '
          + '29.4281 29.4278C26.9276 31.9283 23.5362 33.333 20 33.333ZM21.6667 16.5663L23.4333 18.333L25.2 '
          + '16.5663L26.9667 18.333L28.7333 16.5663L25.2 13.033L21.6667 16.5663ZM14.8 16.5663L16.5667 '
          + '18.333L18.3333 16.5663L14.8 13.033L11.2667 16.5663L13.0333 18.333L14.8 16.5663ZM20 '
          + '29.1663C23.8833 29.1663 27.1833 26.733 28.5167 23.333H11.4833C12.8167 26.733 16.1167 '
          + '29.1663 20 29.1663Z'
        }
      />
    </S.SvgWrapper>
  );
}

GoodRate.propTypes = {
  $color: PropTypes.string,
  $size: PropTypes.number,
  onClick: PropTypes.func,
};
