import React from 'react';
import PropTypes from 'prop-types';
import systemColors from 'utils/constants/styles/system_colors';

export default function ClockIcon({ $height = 26, $width = 25 }) {
  return (
    <svg width={$width} height={$height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d={
          'M12.4999 2.58398C11.132 2.58398 9.77744 2.85342 8.51363 3.37691C7.24982 3.90039 6.1015 4.66768'
          + ' 5.13422 5.63496C3.18072 7.58846 2.08325 10.238 2.08325 13.0007C2.08325 15.7633 3.18072 18.4128'
          + ' 5.13422 20.3663C6.1015 21.3336 7.24982 22.1009 8.51363 22.6244C9.77744 23.1479 11.132 23.4173'
          + ' 12.4999 23.4173C15.2626 23.4173 17.9121 22.3199 19.8656 20.3663C21.8191 18.4128 22.9166 15.7633'
          + ' 22.9166 13.0007C22.9166 11.6327 22.6472 10.2782 22.1237 9.01437C21.6002 7.75056 20.8329 6.60223'
          + ' 19.8656 5.63496C18.8983 4.66768 17.75 3.90039 16.4862 3.37691C15.2224 2.85342 13.8679 2.58398'
          + ' 12.4999 2.58398ZM16.8749 17.3757L11.4583 14.0423V7.79232H13.0208V13.209L17.7083 16.0215L16.8749'
          + ' 17.3757Z'
        }
        fill={systemColors.darkGrayColor}
      />
    </svg>
  );
}

ClockIcon.propTypes = {
  $height: PropTypes.number,
  $width: PropTypes.number,
};
