import React from 'react';
import { enableMapSet } from 'immer';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';
import { I18nextProvider } from 'react-i18next';
import { ConfigProvider } from 'antd';
import ruRu from 'antd/lib/locale/ru_RU';
import store from 'store';
import App from 'App';
import i18n from './i18n';
import rollbarConfig from './rollbarConfig';

document.addEventListener('DOMContentLoaded', () => {
  enableMapSet();

  render(
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary>
        <I18nextProvider i18n={i18n}>
          <ConfigProvider locale={ruRu}>
            <Provider store={store}>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </Provider>
          </ConfigProvider>
        </I18nextProvider>
      </ErrorBoundary>
    </RollbarProvider>,
    document.body.appendChild(document.querySelector('.layout')),
  );
});
