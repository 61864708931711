import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import ru from './utils/constants/locales/ru.json';

i18n.use(initReactI18next).init({
  interpolation: {
    format: (value, format) => {
      if (format === 'long') { return moment.parseZone(value).format('DD MMMM YYYY, HH:mm'); }
      if (format === 'short') { return moment.parseZone(value).format('DD.MM.YYYY'); }
      if (format === 'time') { return moment.parseZone(value).format('HH:mm'); }
      if (format === 'date') { return moment.parseZone(value).format('DD MMMM YYYY'); }
      if (format === 'localized_date') { return moment.parseZone(value).format('LL'); }
      if (format === 'calendar_date') {
        return moment.parseZone(value).calendar(
          null, { lastWeek: 'DD.MM.YYYY HH:mm', sameElse: 'DD.MM.YYYY HH:mm' },
        );
      }
      return value;
    },
  },
  resources: {
    ru,
  },
  fallbackLng: 'ru',
  ns: ['translations'],
  defaultNS: 'translations',
});

i18n.on('languageChanged', (lng) => moment.locale(lng));

moment.updateLocale('en', {
  calendar: {
    lastDay: '[Yesterday] HH:mm',
    sameDay: '[Today] HH:mm',
    nextDay: '[Tomorrow] HH:mm',
    lastWeek: 'DD.MM.YYYY HH:mm',
    nextWeek: 'DD.MM.YYYY HH:mm',
    sameElse: 'DD.MM.YYYY HH:mm',
  },
});

export default i18n;

export const humanAttributeName = (objectName, attributeName) => (
  `${i18n.t(`activerecord.attributes.${objectName}.${attributeName}`)}:`
);
