import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import * as S from './Rate.styles';

const RATE_VALUE = 'bad';

export default function BadRate({ $color, $size = 40, onClick }) {
  const handleClick = useCallback((e) => {
    if (onClick) { onClick(e, RATE_VALUE); }
  }, [onClick]);

  return (
    <S.SvgWrapper
      $color={$color}
      width={$size}
      height={$size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={handleClick}
    >
      <path
        d={
          'M33.3333 19.9997C33.3333 16.4635 31.9285 13.0721 29.428 10.5716C26.9275 8.0711 23.5361 6.66634 '
          + '19.9999 6.66634C16.4637 6.66634 13.0723 8.0711 10.5718 10.5716C8.07134 13.0721 6.66659 16.4635 '
          + '6.66659 19.9997C6.66659 23.5359 8.07134 26.9273 10.5718 29.4278C13.0723 31.9283 16.4637 33.333 '
          + '19.9999 33.333C23.5361 33.333 26.9275 31.9283 29.428 29.4278C31.9285 26.9273 33.3333 23.5359 '
          + '33.3333 19.9997ZM36.6666 19.9997C36.6666 24.42 34.9106 28.6592 31.785 31.7848C28.6594 34.9104 '
          + '24.4202 36.6663 19.9999 36.6663C17.8112 36.6663 15.644 36.2352 13.6219 35.3977C11.5998 34.5601 '
          + '9.76245 33.3324 8.21481 31.7848C5.0892 28.6592 3.33325 24.42 3.33325 19.9997C3.33325 15.5794 '
          + '5.0892 11.3402 8.21481 8.21456C11.3404 5.08896 15.5796 3.33301 19.9999 3.33301C22.1886 3.33301 '
          + '24.3559 3.7641 26.378 4.60168C28.4001 5.43926 30.2374 6.66692 31.785 8.21456C33.3327 9.7622 '
          + '34.5603 11.5995 35.3979 13.6216C36.2355 15.6437 36.6666 17.811 36.6666 19.9997ZM25.8333 '
          + '13.333C27.1666 13.333 28.3333 14.4997 28.3333 15.833C28.3333 17.1663 27.1666 18.333 25.8333 '
          + '18.333C24.4999 18.333 23.3333 17.1663 23.3333 15.833C23.3333 14.4997 24.4999 13.333 25.8333 '
          + '13.333ZM16.6666 15.833C16.6666 17.1663 15.4999 18.333 14.1666 18.333C12.8333 18.333 11.6666 '
          + '17.1663 11.6666 15.833C11.6666 14.4997 12.8333 13.333 14.1666 13.333C15.4999 13.333 16.6666 '
          + '14.4997 16.6666 15.833ZM19.9999 23.333C22.9166 23.333 25.4833 24.533 26.9833 26.3497L24.6166 '
          + '28.7163C23.8666 27.4997 22.0833 26.6663 19.9999 26.6663C17.9166 26.6663 16.1333 27.4997 15.3833 '
          + '28.7163L13.0166 26.3497C14.5166 24.533 17.0833 23.333 19.9999 23.333Z'
        }
      />
    </S.SvgWrapper>
  );
}

BadRate.propTypes = {
  $color: PropTypes.string,
  $size: PropTypes.number,
  onClick: PropTypes.func,
};
