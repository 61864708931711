import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import systemColors from 'utils/constants/styles/system_colors';

const SvgWrapper = styled.svg`
  fill: ${systemColors.darkGrayColor};
`;

export default function ContractorIcon({ $height = 20, $width = 20 }) {
  return (
    <SvgWrapper
      width={$width}
      height={$height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={
          'M9.72631 12.5055C6.56253 12.5055 4 13.7867 4 15.3686V16.8002H15.4526V15.3686C15.4526 13.7867 '
          + '12.8901 12.5055 9.72631 12.5055ZM6.86316 8.21072C6.86316 8.97008 7.16481 9.69833 7.70176 '
          + '10.2353C8.2387 10.7722 8.96696 11.0739 9.72631 11.0739C10.4857 11.0739 11.2139 10.7722 11.7509 '
          + '10.2353C12.2878 9.69833 12.5895 8.97008 12.5895 8.21072H6.86316ZM9.36842 3.2002C9.15368 3.2002 '
          + '9.01053 3.35051 9.01053 3.55809V5.70546H8.29474V3.91598C8.29474 3.91598 6.68421 4.53156 6.68421 '
          + '6.60019C6.68421 6.60019 6.14737 6.70041 6.14737 7.49493H13.3053C13.2695 6.70041 12.7684 6.60019 '
          + '12.7684 6.60019C12.7684 4.53156 11.1579 3.91598 11.1579 3.91598V5.70546H10.4421V3.55809C10.4421 '
          + '3.35051 10.3061 3.2002 10.0842 3.2002H9.36842Z'
        }
      />
    </SvgWrapper>
  );
}

ContractorIcon.propTypes = {
  $height: PropTypes.number,
  $width: PropTypes.number,
};
