import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RefreshIcon } from 'components/icons';
import systemColors from 'utils/constants/styles/system_colors';

const ButtonWrapper = styled.div`
  background: ${systemColors.defaultColor};
  border-radius: 100px;
  width: 40px;
  height: 40px;
  position: relative;
  box-sizing: border-box;
  border: 3px solid ${systemColors.defaultColor};
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    border: 3px solid ${systemColors.hoverColor};
  }
`;

export default function RefreshButton({ onClick }) {
  return (
    <ButtonWrapper onClick={onClick}>
      <RefreshIcon />
    </ButtonWrapper>
  );
}

RefreshButton.propTypes = {
  onClick: PropTypes.func,
};
