import React from 'react';
import PropTypes from 'prop-types';

export default function PriorityIcon({ $color }) {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d={
          'M15.2708 14.7705C15.3021 14.8851 15.3125 14.9997 15.3125 15.1038C15.3437 15.7809 15.0416 16.5101 '
          + '14.5521 16.958C14.3229 17.1559 13.9479 17.3643 13.6562 17.4476C12.7396 17.7705 11.8229 17.3122 '
          + '11.2812 16.7809C12.2604 16.5518 12.8333 15.8434 13.0208 15.1038C13.1458 14.4684 12.8854 13.9372 '
          + '12.7812 13.3122C12.6771 12.708 12.6979 12.1976 12.9166 11.6455C13.0729 11.9476 13.2396 12.2601 '
          + '13.4375 12.4997C14.0625 13.3122 15.0521 13.6663 15.2708 14.7705ZM22.9166 12.4997C22.9166 '
          + '18.2288 18.2291 22.9163 12.5 22.9163C6.77081 22.9163 2.08331 18.2288 2.08331 12.4997C2.08331 '
          + '6.77051 6.77081 2.08301 12.5 2.08301C18.2291 2.08301 22.9166 6.77051 22.9166 12.4997ZM17.875 '
          + '13.083L17.7708 12.8747C17.6041 12.4997 17.1354 11.8538 17.1354 11.8538C16.9479 11.6143 16.7187 '
          + '11.3955 16.5104 11.1872C15.9583 10.6976 15.3437 10.3538 14.8125 9.84342C13.5937 8.65592 '
          + '13.3229 6.70801 14.1041 5.20801C13.3229 5.39551 12.6458 5.81218 12.0625 6.28092C9.9479 '
          + '7.96843 9.11456 10.9372 10.1146 13.4893C10.1458 13.5726 10.1771 13.6559 10.1771 13.7601C10.1771 '
          + '13.9372 10.0521 14.0934 9.89581 14.1663C9.6979 14.2393 9.5104 14.1976 9.37498 14.0622C9.30206 '
          + '14.0205 9.27081 13.9788 9.23956 13.9268C8.33331 12.7705 8.16665 11.1038 8.78123 9.78093C7.41665 '
          + '10.8851 6.67706 12.7497 6.77081 14.4997C6.83331 14.9059 6.87498 15.3122 7.02081 15.7184C7.1354 '
          + '16.208 7.35415 16.6663 7.60415 17.1247C8.47915 18.5205 10.0104 19.5309 11.6562 19.7288C13.4062 '
          + '19.9476 15.2812 19.6351 16.625 18.4372C18.125 17.083 18.6458 14.9268 17.875 13.083Z'
        }
        fill={$color}
      />
    </svg>
  );
}

PriorityIcon.propTypes = {
  $color: PropTypes.string,
};
