import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

/* eslint no-param-reassign: 0 */
export const backendPoliciesSlice = createSlice({
  name: 'backendPoliciesSlice',
  initialState,
  reducers: {
    fillPolicies(state, { payload: { policyKey, policyValues } }) {
      if (!state[policyKey]) { state[policyKey] = {}; }
      state[policyKey] = { ...state[policyKey], ...policyValues };
    },
  },
  extraReducers: {},
});
/* eslint no-param-reassign: 1 */

export const { fillPolicies } = backendPoliciesSlice.actions;
