import React from 'react';
import PropTypes from 'prop-types';
import systemColors from 'utils/constants/styles/system_colors';

export default function CloseIcon({ $height = 25, $width = 25 }) {
  return (
    <svg width={$width} height={$height} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d={
          'M14.021 12.5007L19.7918 18.2715V19.7923H18.271L12.5002 14.0215L6.72933'
          + ' 19.7923H5.2085V18.2715L10.9793 12.5007L5.2085 6.72982V5.20898H6.72933L12.5002 10.9798L18.271'
          + ' 5.20898H19.7918V6.72982L14.021 12.5007Z'
        }
        fill={systemColors.inactiveColor}
      />
    </svg>
  );
}

CloseIcon.propTypes = {
  $height: PropTypes.number,
  $width: PropTypes.number,
};
