import React from 'react';
import systemColors from 'utils/constants/styles/system_colors';

export default function EquipmentIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d={
          'M16.5912 16.3685L15.5688 17.3908C15.4339 17.5249 15.2515 17.6002 15.0613 17.6002C14.8711 17.6002 '
          + '14.6886 17.5249 14.5537 17.3908L6.00042 8.85197C5.7663 8.9198 5.52417 8.95612 5.28045 '
          + '8.95997C4.82205 8.95964 4.37034 8.84989 3.9629 8.63984C3.55545 8.42979 3.20403 8.12552 2.93785 '
          + '7.75231C2.67168 7.3791 2.49844 6.94774 2.43254 6.4941C2.36664 6.04046 2.40998 5.57764 2.55896 '
          + '5.14412L4.38769 6.97285L4.76927 6.59126L5.79163 5.5689L6.17321 5.18732L4.34449 3.35859C4.77801 '
          + '3.20961 5.24083 3.16627 5.69447 3.23217C6.14811 3.29807 6.57947 3.47131 6.95268 3.73748C7.32589 '
          + '4.00366 7.63016 4.35508 7.84021 4.76253C8.05026 5.16997 8.16001 5.62168 8.16034 6.08008C8.15649 '
          + '6.3238 8.12017 6.56593 8.05234 6.80005L16.5912 15.3533C16.7253 15.4882 16.8006 15.6707 16.8006 '
          + '15.8609C16.8006 16.0511 16.7253 16.2336 16.5912 16.3685ZM2.60936 15.3533C2.47526 15.4882 '
          + '2.39999 15.6707 2.39999 15.8609C2.39999 16.0511 2.47526 16.2336 2.60936 16.3685L3.63172 '
          + '17.3908C3.76661 17.5249 3.94909 17.6002 4.1393 17.6002C4.3295 17.6002 4.51198 17.5249 4.64688 '
          + '17.3908L8.58512 13.4598L6.5476 11.4223L2.60936 15.3533ZM15.3601 3.2002L12.4802 '
          + '4.64014V6.08008L10.9178 7.64242L12.3578 9.08236L13.9201 7.52002H15.3601L16.8 '
          + '4.64014L15.3601 3.2002Z'
        }
        fill={systemColors.darkGrayColor}
      />
    </svg>
  );
}
