import styled from 'styled-components';
import { Layout } from 'antd';

export const LayoutWrapper = styled(Layout)`
  min-height: 100%;
`;

export const LayoutContentWrapper = styled(LayoutWrapper)`
  padding-top: 80px;
  flex-direction: row;
`;

export const LayoutHeaderWrapper = styled(Layout.Header)`
  position: fixed;
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background: #FFFFFF;
  padding: 0px 30px;
  height: 80px;
  flex-direction: row;
`;

export const ContentWrapper = styled(Layout.Content)`
  padding: 0px 20px 0 120px;
  background-color: white;
  width: 100%;
`;

export const HeaderIcons = styled.div`
  padding-left: 15px;
`;

export const LogOutIconWrapper = styled.div`
  display: flex;
`;
