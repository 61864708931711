import React from 'react';

export default function FullScreenIcon() {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d={
          'M3.82471 3.125H6.94971V4.375H5.07471V6.25H3.82471V3.125ZM9.44971 3.125H12.5747V6.25H11.'
          + '3247V4.375H9.44971V3.125ZM11.3247 8.75H12.5747V11.875H9.44971V10.625H11.3247V8.75ZM6.94971'
          + ' 10.625V11.875H3.82471V8.75H5.07471V10.625H6.94971Z'
        }
        fill="#898B8F"
      />
    </svg>
  );
}
