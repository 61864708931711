import React from 'react';

export default function SortIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d={
          'M18.7502 21.875L14.5835 17.7083H17.7085V7.29167H14.5835L18.7502 3.125L22.9168 7.29167H19.7918V17'
          + '.7083H22.9168L18.7502 21.875ZM2.0835 19.7917V17.7083H12.5002V19.7917H2.0835ZM2.0835'
          + ' 13.5417V11.4583H9.37516V13.5417H2.0835ZM2.0835 7.29167V5.20833H6.25016V7.29167H2.0835Z'
        }
        fill="#5A5D62"
      />
    </svg>
  );
}
