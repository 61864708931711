import styled from 'styled-components';
import { Checkbox } from 'antd';
import systemColors from 'utils/constants/styles/system_colors';

const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox {
    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: ${systemColors.defaultColor};
        border-color: ${systemColors.defaultColor};
      }

      &:after {
        border: 1px solid ${systemColors.defaultColor};
      }
    }

    &:hover {
      .ant-checkbox-inner {
        border-color: ${systemColors.defaultColor};
      }
    }
  }

  &:hover {
    .ant-checkbox-inner {
      border-color: ${systemColors.defaultColor};
    }
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${systemColors.defaultColor};
  }
`;

export default StyledCheckbox;
