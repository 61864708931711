import React from 'react';
import PropTypes from 'prop-types';
import systemColors from 'utils/constants/styles/system_colors';
import styled from 'styled-components';

const SvgWrapper = styled.svg`
  fill: ${systemColors.defaultColor};
`;

export default function CheckIcon({ $width = 25, $height = 25 }) {
  return (
    <SvgWrapper
      width={$width}
      height={$height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={
          'M9.375 21.271L2.90625 14.8022L5.85417 11.8543L9.375 '
          + ' 15.3856L19.6667 5.0835L22.6146 8.03141L9.375 21.271Z'
        }
      />
    </SvgWrapper>
  );
}

CheckIcon.propTypes = {
  $height: PropTypes.number,
  $width: PropTypes.number,
};
