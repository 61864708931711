import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n';
import ReactPhoneInput2 from 'react-phone-input-2';
import ru from 'react-phone-input-2/lang/ru.json';
import InputWrapper from './PhoneInput.styles';

// Realise custom phone input with country code select
export default function PhoneInput({
  defaultCountry, value, enableSearch, validateStatus, inputProps, onChange, onKeyDown,
}) {
  return (
    <InputWrapper>
      <ReactPhoneInput2
        disableSearchIcon
        countryCodeEditable={false}
        localization={ru}
        value={value}
        country={defaultCountry}
        enableSearch={enableSearch}
        searchPlaceholder={i18n.t('phone_input.search')}
        searchNotFound={i18n.t('phone_input.not_found_country')}
        inputClass={`custom-phone-input with-${validateStatus}`}
        buttonClass="custom-phone-language-btn"
        dropdownClass="custom-phone-input-country-dropdown"
        searchClass="custom-phone-input-country-search"
        inputProps={{ ...inputProps }}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
    </InputWrapper>
  );
}

PhoneInput.propTypes = {
  defaultCountry: PropTypes.string,
  value: PropTypes.string,
  enableSearch: PropTypes.bool,
  validateStatus: PropTypes.string,
  inputProps: PropTypes.object,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
};
