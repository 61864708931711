import React from 'react';

export default function InactiveIcon() {
  return (
    <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d={
          'M15.0833 2.0835C12.8731 2.0835 10.7535 2.96147 9.1907 4.52427C7.62789 6.08708 6.74992 8.20669'
          + ' 6.74992 10.4168C6.74992 12.627 7.62789 14.7466 9.1907 16.3094C10.7535 17.8722 12.8731 18.7502'
          + ' 15.0833 18.7502C17.2934 18.7502 19.413 17.8722 20.9758 16.3094C22.5386 14.7466 23.4166 12.627'
          + ' 23.4166 10.4168C23.4166 8.20669 22.5386 6.08708 20.9758 4.52427C19.413 2.96147 17.2934 2.0835'
          + ' 15.0833 2.0835ZM15.0833 4.16683C18.5416 4.16683 21.3333 6.96891 21.3333 10.4168C21.3333 13.8752'
          + ' 18.5416 16.6668 15.0833 16.6668C13.4256 16.6668 11.8359 16.0083 10.6638 14.8362C9.49173 13.6641'
          + ' 8.83325 12.0744 8.83325 10.4168C8.83325 8.75923 9.49173 7.16951 10.6638 5.99741C11.8359 4.82531'
          + ' 13.4256 4.16683 15.0833 4.16683ZM5.63534 6.06266C3.70825 7.646 2.58325 10.0106 2.58325'
          + ' 12.5002C2.58325 14.7103 3.46123 16.8299 5.02403 18.3927C6.58683 19.9555 8.70645 20.8335 10.9166'
          + ' 20.8335C11.5833 20.8335 12.2395 20.7502 12.8749 20.5939C11.0416 20.1877 9.35409 19.271 7.96867'
          + ' 18.0106C5.93742 16.9272 4.66659 14.8022 4.66659 12.5002C4.66659 12.1877 4.69784 11.8856 4.7395'
          + ' 11.5731C4.69784 11.1877 4.66659 10.8022 4.66659 10.4168C4.66659 8.91683 4.99992 7.42725 5.63534'
          + ' 6.06266Z'
        }
        fill="#898B8F"
      />
    </svg>
  );
}
