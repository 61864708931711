import React from 'react';
import PropTypes from 'prop-types';
import systemColors from 'utils/constants/styles/system_colors';

export default function CalendarIcon({ $height = 26, $width = 25 }) {
  return (
    <svg width={$width} height={$height} viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d={
          'M15.625 14.041H17.1875V16.9785L19.7292 18.4473L18.9479 19.8014L15.625 17.8848V14.041ZM19.7917'
          + ' 8.83268H5.20833V20.291H10.0729C9.625 19.3431 9.375 18.2806 9.375 17.166C9.375 15.2321 10.1432'
          + ' 13.3775 11.5107 12.01C12.8781 10.6426 14.7328 9.87435 16.6667 9.87435C17.7812 9.87435 18.8437'
          + ' 10.1243 19.7917 10.5723V8.83268ZM5.20833 22.3743C4.05208 22.3743 3.125 21.4368 3.125'
          + ' 20.291V5.70768C3.125 4.55143 4.05208 3.62435 5.20833'
          + ' 3.62435H6.25V1.54102H8.33333V3.62435H16.6667V1.54102H18.75V3.62435H19.7917C20.3442 3.62435'
          + ' 20.8741 3.84384 21.2648 4.23454C21.6555 4.62524 21.875 5.15515 21.875 5.70768V12.0618C23.1667'
          + ' 13.3743 23.9583 15.1764 23.9583 17.166C23.9583 19.0999 23.1901 20.9545 21.8227 22.322C20.4552'
          + ' 23.6895 18.6005 24.4577 16.6667 24.4577C14.6771 24.4577 12.875 23.666 11.5625'
          + ' 22.3743H5.20833ZM16.6667 12.1139C15.3268 12.1139 14.0418 12.6462 13.0943 13.5937C12.1469'
          + ' 14.5411 11.6146 15.8261 11.6146 17.166C11.6146 19.9577 13.875 22.2181 16.6667 22.2181C17.3301'
          + ' 22.2181 17.9871 22.0874 18.6 21.8335C19.213 21.5796 19.7699 21.2075 20.239 20.7384C20.7082'
          + ' 20.2692 21.0803 19.7123 21.3342 19.0994C21.5881 18.4864 21.7188 17.8295 21.7188 17.166C21.7188'
          + ' 14.3743 19.4583 12.1139 16.6667 12.1139Z'
        }
        fill={systemColors.darkGrayColor}
      />
    </svg>
  );
}

CalendarIcon.propTypes = {
  $height: PropTypes.number,
  $width: PropTypes.number,
};
