import React from 'react';
import systemColors from 'utils/constants/styles/system_colors';

export default function AgreementIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d={
          'M14.1667 2.5C14.6087 2.5 15.0326 2.67559 15.3452 2.98816C15.6577 3.30072 15.8333 3.72464 15.8333 '
          + '4.16667V17.5L9.99999 15L4.16666 17.5V4.16667C4.16666 3.24167 4.91666 2.5 5.83332 '
          + '2.5H14.1667ZM9.16666 11.6667L14.375 6.46667L13.2 5.28333L9.16666 9.31667L7.00832 '
          + '7.15833L5.83332 8.33333L9.16666 11.6667Z'
        }
        fill={systemColors.darkGrayColor}
      />
    </svg>
  );
}
