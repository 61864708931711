import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { SpinnerLoader } from 'components/loaders';
import { CloseIcon } from 'components/icons';
import Checkbox from 'components/forms/inputs/Checkbox/Checkbox.styles';
import * as S from './MultiSelect.styles';

const { Option } = S.StyledSelect;

export default function MultiSelect({
  selectedValues, options, optionPresenter, closedByDefault, ...props
}) {
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    // Open options widget only after first select render
    if (!closedByDefault) { setIsOpened(true); }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterOptionsHandler = useCallback((input, option) => (
    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
  ), []);

  const buildSelectOptions = useCallback(() => (
    options.map((item) => (
      <Option key={item.value}>
        <Checkbox checked={selectedValues?.includes(item.value)} />
        <S.OptionLabel title={item.label}>
          { optionPresenter ? optionPresenter(item) : item.label }
        </S.OptionLabel>
      </Option>
    ))
  ), [options, selectedValues, optionPresenter]);

  const buildNotFoundContent = useCallback(() => {
    if (props.loading) {
      return (<S.LoaderWrapper><SpinnerLoader /></S.LoaderWrapper>);
    }

    return props.notFoundContent;
  }, [props.loading, props.notFoundContent]);

  const buildMaxTagPlaceholder = useCallback((selectedArray) => (
    i18n.t('multiselect.selected_value', { count: selectedArray?.length || 0 })
  ), []);

  const onDropdownVisibleChange = useCallback((open) => {
    setIsOpened(open);
  }, []);

  return (
    <S.StyledSelect
      open={isOpened}
      clearIcon={<CloseIcon />}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      showArrow
      mode="multiple"
      bordered={false}
      placeholder={props.placeholder || i18n.t('multiselect.placeholder')}
      dropdownClassName={`custom-multiselect-dropdown ${props.size}`}
      filterOption={filterOptionsHandler}
      menuItemSelectedIcon={<></>}
      notFoundContent={buildNotFoundContent()}
      maxTagCount={0}
      maxTagPlaceholder={buildMaxTagPlaceholder}
      onDropdownVisibleChange={onDropdownVisibleChange}
    >
      {buildSelectOptions()}
    </S.StyledSelect>
  );
}

MultiSelect.propTypes = {
  closedByDefault: PropTypes.bool,
  selectedValues: PropTypes.array,
  options: PropTypes.array,
  size: PropTypes.string,
  loading: PropTypes.bool,
  notFoundContent: PropTypes.any,
  optionPresenter: PropTypes.func,
  placeholder: PropTypes.string,
};
