import styled from 'styled-components';
import systemColors from 'utils/constants/styles/system_colors';

export const IconWrapper = styled.div`
  background: ${({ $bgColor }) => ($bgColor || systemColors.bgColor)};
  border-radius: 100px;
  width: ${({ $width }) => `${$width}px;`}
  height: ${({ $height }) => `${$height}px;`}
  position: relative;
  box-sizing: border-box;
  border: ${({ $borderSize, $bgColor }) => `${$borderSize}px solid ${$bgColor || systemColors.bgColor}`};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ChildrenWrapper = styled.div`
  padding-left: 10px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  &:hover {
    cursor: pointer;

    ${IconWrapper} {
      border: ${({ $borderSize }) => `${$borderSize}px`} solid ${systemColors.hoverColor};
    }
  }
`;
