import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import useOnHover from 'hooks/navigation/useOnHover';
import { RadioButtonIcons } from 'components/icons';
import systemColors from 'utils/constants/styles/system_colors';
import * as S from './RadioButton.styles';

export default function RadioButton({
  isSelected, value, label, onClick,
}) {
  const [buttonRef, isHovered] = useOnHover();

  const handleClick = useCallback(() => {
    onClick(value);
  }, [value, onClick]);

  const iconColor = isSelected || isHovered ? systemColors.defaultColor : systemColors.darkGrayColor;

  return (
    <S.ButtonWrapper ref={buttonRef} onClick={handleClick}>
      <S.IconWrapper>
        {
          isSelected ? (
            <RadioButtonIcons.SelectedIcon iconColor={iconColor} />
          ) : (
            <RadioButtonIcons.UnselectedIcon iconColor={iconColor} />
          )
        }
      </S.IconWrapper>
      <S.LabelWrapper>{ label }</S.LabelWrapper>
    </S.ButtonWrapper>
  );
}

RadioButton.propTypes = {
  isSelected: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.bool]),
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onClick: PropTypes.func,
};
