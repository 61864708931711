import styled from 'styled-components';
import { Form as AntdForm } from 'antd';
import systemColors from 'utils/constants/styles/system_colors';
import { SignatureInputCanvas } from './inputs/SignaturePad/SignaturePad.styles';
import { StyledFictiveInput } from './inputs/FictiveInput/FictiveInput.styles';
import { StyledDatePicker } from './inputs/DatePicker/DatePicker.styles';

export const Form = styled(AntdForm)`
  .ant-row.ant-form-item {
    &.ant-form-item-has-error {
      .ant-col.ant-form-item-control {
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            .ant-input, .ant-input-affix-wrapper, .ant-input-affix-wrapper-focused, .ant-select,
            .note-editor.note-frame, ${SignatureInputCanvas}, ${StyledDatePicker}, ${StyledFictiveInput} {
              border-color: ${systemColors.errorColor};
              box-shadow: none;

              &:hover, &:focus {
                border-width: 2px !important;
                border-color: ${systemColors.errorColor};
              }
            }
          }
        }
      }
    }

    &.ant-form-item-has-warning {
      .ant-col.ant-form-item-control {
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            .ant-input, .ant-input-affix-wrapper, .ant-input-affix-wrapper-focused, .ant-select,
            .note-editor.note-frame, ${SignatureInputCanvas}, ${StyledDatePicker}, ${StyledFictiveInput} {
              border-color: ${systemColors.alertColor};
              box-shadow: none;

              &.with-warning, &.with-warning:hover, &.with-warning:focus {
                border-color: ${systemColors.alertColor};
              }
            }
          }
        }
      }
    }

    .ant-col.ant-form-item-control {
      .ant-form-item-control-input {
        .ant-form-item-control-input-content {
          .ant-input, .ant-input-affix-wrapper, .ant-input-affix-wrapper-focused, .ant-select {
            border-width: 2px !important;
            box-shadow: none;

            &:hover {
              border-color: ${systemColors.darkGrayColor};
              border-width: 2px !important;
            }

            &:focus {
              border-color: ${systemColors.defaultColor};
              border-width: 2px !important;
            }
          }

          .ant-select.ant-select-open {
            border-color: ${systemColors.defaultColor};
            border-width: 2px !important;
          }
        }
      }
    }
  }

  ${({ $forPopup }) => (
    $forPopup && `
      padding-top: 10px;
      width: 350px;
    `
  )}

  ${({ $maxWidth }) => (
    $maxWidth && `
      max-width: ${$maxWidth};
    `
  )}

  ${({ $withInlineInputs }) => (
    $withInlineInputs && `
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      flex-shrink: 0;
      flex-grow: 0;
      justify-content: space-between;
    `
  )}
`;

export const FormError = styled.div`
  color: ${systemColors.errorColor};
  padding-bottom: 10px;
`;
