import styled from 'styled-components';
import systemColors from 'utils/constants/styles/system_colors';

export const SidebarItem = styled.div`
  height: 100px;
  width: 100%;
  border-radius: 0px 10px 10px 0px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;

  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.01em;
  color: ${systemColors.darkGrayColor};

  &:hover {
    color: ${systemColors.defaultColor};

    svg {
      fill: ${systemColors.defaultColor};
    }
  }
`;

export const SidebarWrapper = styled.aside`
  position: fixed;
  z-index: 1;
  height: 100%;

  flex: 0 0 100px;
  flex-direction: column;
  max-width: 100px;
  min-width: 100px;
  width: 100px;
  background: white;

  .active-sidebar-item {
    ${SidebarItem} {
      color: #13171E;
      background: #EEF7EB;

      svg {
        fill: #13171E;
      }

      &:hover {
        color: ${systemColors.defaultColor};

        svg {
          fill: ${systemColors.defaultColor};
        }
      }
    }
  }
`;

export const SidebarBottomBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 100px;
`;
