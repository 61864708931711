import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SpinnerLoader } from 'components/loaders';
import { CloseIcon, CheckIcon } from 'components/icons';
import * as S from './Select.styles';

const { Option } = S.StyledSelect;

// Realise custom select with search
export default function Select({
  closedByDefault, borderless, options, optionPresenter, ...props
}) {
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    // Open options widget only after first select render
    if (!closedByDefault) { setIsOpened(true); }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterOptionsHandler = useCallback((input, option) => (
    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
  ), []);

  const buildSelectOptions = useCallback(() => (
    options.map((item) => (
      <Option key={item.value} value={item.value} label={item.label}>
        <S.OptionWrapper>
          <S.OptionIsSelectedContainer>
            {
              (props.value === item.value || props.defaultValue === item.value)
                && <CheckIcon $width={20} $height={20} />
            }
          </S.OptionIsSelectedContainer>

          <S.OptionLabel title={item.label}>
            { optionPresenter ? optionPresenter(item) : item.label }
          </S.OptionLabel>
        </S.OptionWrapper>
      </Option>
    ))
  ), [options, props.value, props.defaultValue, optionPresenter]);

  const buildNotFoundContent = useCallback(() => {
    if (props.loading) {
      return (<S.LoaderWrapper><SpinnerLoader /></S.LoaderWrapper>);
    }

    return props.notFoundContent;
  }, [props.loading, props.notFoundContent]);

  const onDropdownVisibleChange = useCallback((open) => {
    setIsOpened(open);
  }, []);

  return (
    <>
      <S.StyledSelect
        $borderless={borderless}
        open={isOpened}
        showArrow={!borderless}
        optionLabelProp="label"
        clearIcon={<CloseIcon />}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        bordered={false}
        dropdownClassName={`custom-select-dropdown ${props.size}`}
        filterOption={filterOptionsHandler}
        notFoundContent={buildNotFoundContent}
        onDropdownVisibleChange={onDropdownVisibleChange}
      >
        {buildSelectOptions()}
      </S.StyledSelect>
    </>
  );
}

Select.propTypes = {
  closedByDefault: PropTypes.bool,
  size: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
  borderless: PropTypes.bool,
  notFoundContent: PropTypes.any,
  optionPresenter: PropTypes.func,
};
