const ACCESS_TOKEN = 'd5b5d7f0e86f4e839b9c5610caa3beff';
const ENV = process.env.APP_ENV;

function isEnabled() {
  return ENV === 'staging' || ENV === 'production';
}

const rollbarConfig = {
  accessToken: ACCESS_TOKEN,
  environment: ENV,
  enabled: isEnabled(),
};

export default rollbarConfig;
