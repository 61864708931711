import { useState, useCallback } from 'react';

export default function useModal(initialVisibility) {
  const [isModalVisible, setIsVisible] = useState(initialVisibility);

  const showModal = useCallback((callback) => {
    setIsVisible(true);
    if (callback && callback instanceof Function) { callback(); }
  }, []);

  const hideModal = useCallback((callback) => {
    setIsVisible(false);
    if (callback && callback instanceof Function) { callback(); }
  }, []);

  return [isModalVisible, showModal, hideModal];
}
