import React from 'react';
import PropTypes from 'prop-types';
import * as S from './AuthLayout.styles';

export default function AuthLayout({ children }) {
  return (
    <S.LayoutWrapper>
      <S.LayoutContentWrapper>
        { children }
      </S.LayoutContentWrapper>
    </S.LayoutWrapper>
  );
}

AuthLayout.propTypes = { children: PropTypes.object };
