import styled from 'styled-components';
import systemColors from 'utils/constants/styles/system_colors';

export default styled.div`
  .react-tel-input {
    .custom-phone-input.form-control {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 20px 20px 20px 50px;
      height: 67px;
      width: 100%;
      border-radius: 10px;
      box-sizing: border-box;
      font-size: 20px;
      line-height: 27px;
      letter-spacing: 0.01em;
      border: 2px solid ${systemColors.darkGrayColor};

      &.with-error, &.with-error:hover, &.with-error:focus {
        border-color: ${systemColors.errorColor};
      }

      &.with-warning, &.with-warning:hover, &.with-warning:focus {
        border-color: ${systemColors.alertColor};
      }

      &:hover {
        border-color: ${systemColors.darkGrayColor};
      }

      &:focus {
        border-color: ${systemColors.defaultColor};
      }

      &.open {
        border-color: ${systemColors.defaultColor};
      }
    }

    .custom-phone-language-btn.flag-dropdown {
      border: none;
      background: transparent !important;

      .selected-flag.open, &.open {
        border: none;
        background: transparent;
      }

      .selected-flag:hover, .selected-flag:focus {
        border: none;
        background-color: transparent;
      }

      .custom-phone-input-country-dropdown.country-list {
        padding: 0px;
        width: 350px;
        height: 268px;
        background: #FFFFFF;
        box-shadow: -4px -4px 15px rgba(0, 0, 0, 0.15), 4px 4px 15px rgba(0, 0, 0, 0.15);
        border-radius: 10px;

        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 27px;
        letter-spacing: 0.01em;

        &::-webkit-scrollbar {
          background: #FFFFFF;
          border-radius: 5px;
          width: 5px;
        }

        &::-webkit-scrollbar-thumb{
          background: #898B8F;
          border-radius: 5px;
          width: 5px;
        }

        .custom-phone-input-country-search.search {
          .search-box.custom-phone-input-country-search-box {
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 27px;
            letter-spacing: 0.01em;
            border: none;
          }
        }
      }
    }
  }
`;
