import React from 'react';
import PropTypes from 'prop-types';
import systemColors from 'utils/constants/styles/system_colors';
import styled from 'styled-components';

const SvgWrapper = styled.svg`
  fill: ${({ $color }) => $color || systemColors.defaultColor};
`;

export default function EditIcon({ $height = 19, $width = 19, $color }) {
  return (
    <SvgWrapper
      $color={$color}
      width={$width}
      height={$height}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={
          'M18.5729 4.33301C18.9792 3.92676 18.9792 3.24967 18.5729 2.86426L16.1354 0.426758C15.75'
          + ' 0.0205078 15.0729 0.0205078 14.6667 0.426758L12.75 2.33301L16.6563 6.23926L18.5729'
          + '4.33301ZM0.125 14.9684V18.8747H4.03125L15.5521 7.34343L11.6458 3.43717L0.125 14.9684Z'
        }
      />
    </SvgWrapper>
  );
}

EditIcon.propTypes = {
  $height: PropTypes.number,
  $width: PropTypes.number,
  $color: PropTypes.string,
};
