import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  padding-right: 12px;
`;

export const LabelWrapper = styled.div`
  font-family: 'OpenSans';
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.01em;
`;
