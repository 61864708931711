import { combineReducers } from 'redux';
import { userAuthSlice } from './user_auth/user_auth.slice';
import { backendPoliciesSlice } from './backendPolicies/backendPolicies.slice';

const rootReducer = combineReducers({
  user: userAuthSlice.reducer,
  backendPolicies: backendPoliciesSlice.reducer,
});

export default rootReducer;
