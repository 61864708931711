import React from 'react';
import PropTypes from 'prop-types';
import systemColors from 'utils/constants/styles/system_colors';

export default function SignatureIcon({
  $color = systemColors.defaultColor,
}) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d={
          'M15.0001 11.667H8.75008L10.4167 10.0003H15.0001V11.667ZM5.00008 11.667V9.58366L10.7334 '
          + '3.86699C10.8917 3.70866 11.1584 3.70866 11.3251 3.86699L12.7917 5.34199C12.9584 5.50866 '
          + '12.9584 5.76699 12.7917 5.93366L7.05842 11.667H5.00008ZM16.6667 1.66699H3.33341C2.89139 '
          + '1.66699 2.46746 1.84259 2.1549 2.15515C1.84234 2.46771 1.66675 2.89163 1.66675 '
          + '3.33366V18.3337L5.00008 15.0003H16.6667C17.1088 15.0003 17.5327 14.8247 17.8453 14.5122C18.1578 '
          + '14.1996 18.3334 13.7757 18.3334 13.3337V3.33366C18.3334 2.40866 17.5834 1.66699 16.6667 1.66699Z'
        }
        fill={$color}
      />
    </svg>
  );
}

SignatureIcon.propTypes = {
  $color: PropTypes.string,
};
