import axios from 'axios';
import i18n from 'i18next';
import { message } from 'antd';
import qs from 'qs';
import { logOutUser } from 'store/user_auth/user_auth.reducers';

const axiosInstance = axios.create({
  timeout: 30000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  responseType: 'json',
});

let axiosRequestInterceptor = null;
let axiosResponseInterceptor = null;

function getRequest(url, payload) {
  return axiosInstance.get(url, payload);
}

function postRequest(url, payload) {
  return axiosInstance.post(url, payload);
}

function patchRequest(url, payload) {
  return axiosInstance.patch(url, payload);
}

function deleteRequest(url) {
  return axiosInstance.delete(url);
}

/* eslint no-param-reassign: 0 */
function initInterceptors(dispatch, isAuthorized, history) {
  if (axiosRequestInterceptor) {
    axiosInstance.interceptors.request.eject(axiosRequestInterceptor);
    axiosRequestInterceptor = null;
  }

  if (axiosResponseInterceptor) {
    axiosInstance.interceptors.response.eject(axiosResponseInterceptor);
    axiosResponseInterceptor = null;
  }

  axiosRequestInterceptor = axiosInstance.interceptors.request.use((config) => {
    const csrfToken = document.querySelector('meta[name="csrf-token"]');
    if (csrfToken) { config.headers['X-CSRF-Token'] = csrfToken.getAttribute('content'); }

    config.paramsSerializer = (params) => (
      qs.stringify(params, { arrayFormat: 'brackets', encode: false })
    );

    return config;
  });

  axiosResponseInterceptor = axiosInstance.interceptors.response.use((response) => (
    response
  ), async (error) => {
    const { response } = error;

    if (response.status === 401 && isAuthorized) {
      dispatch(logOutUser()).then(() => history.push('/login'));
    }

    if (isAuthorized && response.status.toString().match(/(4|5)\d\d/)) {
      if (response.data?.errors) {
        if (response.data.errors.base) { message.error(response.data.errors.base); }
      } else {
        message.error(i18n.t('backend_request.errors.invalid_request'));
      }
    }

    // TODO: add logic for 404 page, like:
    // if (response_status === 404) {
    //   history.push('/404');
    // }

    return Promise.reject(error);
  });
}
/* eslint no-param-reassign: 1 */

export {
  getRequest, postRequest, patchRequest, deleteRequest, initInterceptors,
};
