import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ClockIcon } from 'components/icons';
import * as S from './DatePicker.styles';

export default function TimeInput({ value, onChange }) {
  const handleChange = useCallback((e) => {
    if (e.target.value) { onChange(e.target.value); }
  }, [onChange]);

  return (
    <>
      <S.ClockIconWrapper><ClockIcon /></S.ClockIconWrapper>
      <input type="time" value={value} onChange={handleChange} required />
    </>
  );
}

TimeInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};
