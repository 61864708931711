const defaultColor = '#4DAD33';
const alertColor = '#FF7A00';
const errorColor = '#DE1F1F';
const hoverColor = '#A6D699';
const inactiveColor = '#898B8F';
const darkGrayColor = '#5A5D62';
const bgColor = '#E8E8E9';

export default {
  defaultColor, alertColor, errorColor, hoverColor, inactiveColor, darkGrayColor, bgColor,
};
