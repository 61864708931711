import styled from 'styled-components';
import { Select as AntdSelect } from 'antd';
import systemColors from 'utils/constants/styles/system_colors';

export const StyledSelect = styled(AntdSelect)`
  height: ${({ size }) => (size === 'small' ? '52px' : '67px')};
  width: 100%;
  border: 2px solid ${systemColors.darkGrayColor};
  box-sizing: border-box;
  border-radius: 10px;
  background: white;
  font-size: ${({ size }) => (size === 'small' ? '16px' : '20px')};
  line-height: 27px;

  &.ant-select.ant-select-multiple {
    .ant-select-selector {
      cursor: pointer;
      border: none;
      height: 100% !important;
      padding: 0;

      .ant-select-selection-placeholder {
        left: 20px;
      }

      .ant-select-selection-search {
        left: 20px;
        top: 20px;

        input {
          display: none;
        }
      }

      .ant-select-selection-overflow {
        padding: ${({ size }) => (size === 'small' && `
          14px 45px 14px 14px
        `) || `
          20px 45px 20px 20px
        `} !important;
        align-items: center;

        .ant-select-selection-item {
          border: none;
          background: white;
          height: 100%;
          line-height: 135%;
        }
      }
    }

    .ant-select-clear {
      position: absolute;
      top: 0;
      right: 20px;
      width: 30px;
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      opacity: 1;
      transition: none;
      background: transparent;
      margin-top: 0;
    }

    .ant-select-arrow {
      color: #13171E;
    }

    &.ant-select-open {
      .ant-select-arrow {
        color: ${systemColors.defaultColor};

        .anticon-down {
          transform: rotate(180deg);
        }
      }
    }

    &.ant-select-focused {
      .ant-select-selector {
        border: none;
        box-shadow: 0;
      }
    }

    &:hover, &:focus {
      border-color: ${systemColors.darkGrayColor};
    }
  }
`;

export const OptionLabel = styled.div`
  display: inline-block;
  padding-left: 10px;
  vertical-align: middle;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 95%;
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
