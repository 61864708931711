import React, { useCallback } from 'react';
import i18n from 'i18next';
import { NavLink, matchPath } from 'react-router-dom';
import { issuesRoutes } from 'utils/collections/routesMatching';
import { EntityIcons } from 'components/icons';
import * as S from '../Sidebar.styles';

export default function IssuesLink() {
  const calcIsIssuesActive = useCallback((match, location) => {
    if (match?.isExact) { return true; }
    const isIssueRoute = issuesRoutes.some((routePath) => (
      matchPath(location.pathname, { path: routePath, exact: true })
    ));
    if (isIssueRoute) { return true; }

    return false;
  }, []);

  return (
    <NavLink to="/issues" activeClassName="active-sidebar-item" isActive={calcIsIssuesActive}>
      <S.SidebarItem title={i18n.t('layouts.application.issues_link')}>
        <EntityIcons.IssuesIcon />
        {i18n.t('layouts.application.issues_link')}
      </S.SidebarItem>
    </NavLink>
  );
}
