import React from 'react';
import PropTypes from 'prop-types';

export default function SelectedIcon({ iconColor }) {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d={
          'M12.4999 20.834C10.2898 20.834 8.17017 19.956 6.60736 18.3932C5.04456 16.8304 4.16659 14.7108'
          + ' 4.16659 12.5007C4.16659 10.2905 5.04456 8.1709 6.60736 6.60809C8.17017 5.04529 10.2898 4.16732'
          + ' 12.4999 4.16732C14.7101 4.16732 16.8297 5.04529 18.3925 6.60809C19.9553 8.1709 20.8333 10.2905'
          + ' 20.8333 12.5007C20.8333 14.7108 19.9553 16.8304 18.3925 18.3932C16.8297 19.956 14.7101 20.834'
          + ' 12.4999 20.834V20.834ZM12.4999 2.08398C11.132 2.08398 9.77744 2.35342 8.51363 2.87691C7.24982'
          + ' 3.40039 6.1015 4.16768 5.13422 5.13496C3.18072 7.08846 2.08325 9.73798 2.08325 12.5007C2.08325'
          + ' 15.2633 3.18072 17.9128 5.13422 19.8663C6.1015 20.8336 7.24982 21.6009 8.51363 22.1244C9.77744'
          + ' 22.6479 11.132 22.9173 12.4999 22.9173C15.2626 22.9173 17.9121 21.8199 19.8656 19.8663C21.8191'
          + ' 17.9128 22.9166 15.2633 22.9166 12.5007C22.9166 11.1327 22.6472 9.77817 22.1237 8.51437C21.6002'
          + ' 7.25056 20.8329 6.10223 19.8656 5.13496C18.8983 4.16768 17.75 3.40039 16.4862 2.87691C15.2224'
          + ' 2.35342 13.8679 2.08398 12.4999 2.08398V2.08398ZM12.4999 7.29232C11.1186 7.29232 9.79382'
          + ' 7.84105 8.81707 8.8178C7.84032 9.79456 7.29159 11.1193 7.29159 12.5007C7.29159 13.882 7.84032'
          + ' 15.2067 8.81707 16.1835C9.79382 17.1603 11.1186 17.709 12.4999 17.709C13.8813 17.709 15.206'
          + ' 17.1603 16.1828 16.1835C17.1595 15.2067 17.7083 13.882 17.7083 12.5007C17.7083 11.1193 17.1595'
          + ' 9.79456 16.1828 8.8178C15.206 7.84105 13.8813 7.29232 12.4999 7.29232V7.29232Z'
        }
        fill={iconColor}
      />
    </svg>

  );
}

SelectedIcon.propTypes = {
  iconColor: PropTypes.string,
};
