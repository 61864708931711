import React from 'react';
import styled from 'styled-components';
import systemColors from 'utils/constants/styles/system_colors';

const SvgWrapper = styled.svg`
  fill: ${systemColors.darkGrayColor};
`;

export default function IssuesIcon() {
  return (
    <SvgWrapper width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d={
          'M24.9454 24.2095H16.2908V21.7368H24.9454V24.2095ZM28.6544 19.264H16.2908V16.7913H28.6544V19'
          + '.264ZM28.6544 14.3186H16.2908V11.8458H28.6544V14.3186ZM31.1272 6.90039H13.8181C12.4457 6.90039'
          + ' 11.3454 8.00075 11.3454 9.37312V26.6822C11.3454 28.0546 12.4581 29.1549 13.8181 29.1549H31'
          + '.1272C32.4995 29.1549 33.5999 28.0546 33.5999 26.6822V9.37312C33.5999 8.00075 32.4872 6.90039'
          + ' 31.1272 6.90039ZM8.87263 11.8458V31.6277H28.6544V34.1004H8.87263C8.21682 34.1004 7.58787'
          + ' 33.8399 7.12415 33.3761C6.66042 32.9124 6.3999 32.2835 6.3999 31.6277V11.8458H8.87263Z'
        }
      />
    </SvgWrapper>
  );
}
