import React from 'react';
import systemColors from 'utils/constants/styles/system_colors';
import styled from 'styled-components';

const SvgWrapper = styled.svg`
  fill: ${systemColors.darkGrayColor};

  &:hover {
    fill: ${systemColors.defaultColor}
  }
`;

export default function PaperclipIcon() {
  return (
    <SvgWrapper width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d={
          'M16.3597 5.8157V16.793C16.3597 17.8056 15.9574 18.7768 15.2414 19.4928C14.5253 20.2089 13.5541'
          + ' 20.6112 12.5415 20.6112C11.5289 20.6112 10.5577 20.2089 9.84164 19.4928C9.12559 18.7768 8.72332'
          + ' 17.8056 8.72332 16.793V4.86115C8.72332 4.22825 8.97474 3.62127 9.42227 3.17374C9.8698 2.72621'
          + ' 10.4768 2.47479 11.1097 2.47479C11.7426 2.47479 12.3496 2.72621 12.7971 3.17374C13.2446 3.62127'
          + ' 13.496 4.22825 13.496 4.86115V14.8839C13.496 15.137 13.3955 15.3798 13.2165 15.5588C13.0375'
          + ' 15.7379 12.7947 15.8384 12.5415 15.8384C12.2883 15.8384 12.0456 15.7379 11.8665 15.5588C11.6875'
          + ' 15.3798 11.587 15.137 11.587 14.8839V5.8157H10.1551V14.8839C10.1551 15.5168 10.4066 16.1238'
          + ' 10.8541 16.5713C11.3016 17.0188 11.9086 17.2702 12.5415 17.2702C13.1744 17.2702 13.7814 17.0188'
          + ' 14.2289 16.5713C14.6764 16.1238 14.9279 15.5168 14.9279 14.8839V4.86115C14.9279 3.84851 14.5256'
          + ' 2.87734 13.8095 2.16129C13.0935 1.44524 12.1223 1.04297 11.1097 1.04297C10.097 1.04297 9.12587'
          + ' 1.44524 8.40982 2.16129C7.69378 2.87734 7.2915 3.84851 7.2915 4.86115V16.793C7.2915 18.1854'
          + ' 7.84463 19.5207 8.82919 20.5053C9.81376 21.4898 11.1491 22.043 12.5415 22.043C13.9339 22.043'
          + ' 15.2692 21.4898 16.2538 20.5053C17.2384 19.5207 17.7915 18.1854 17.7915 16.793V5.8157H16.3597Z'
        }
      />
    </SvgWrapper>
  );
}
